@if(form){
<form [formGroup]="form" class="col-lg-12" *ngIf="form">
  @if(showIgnoreStayToggle){
  <wilson-necessary-stay-toggle
    [currentShiftNecessaryStay]="
      !!form.controls[
        TimelineStayManagementDrawerFormFieldEnum.IsOverNightStayRequired
      ].value
    "
    [boldText]="true"
    (updatedNecessaryStay)="toggleFormState($event)"
  ></wilson-necessary-stay-toggle>
  }
  <ng-container
    *ngIf="{
      isFormEditable:
        !!form.controls[
          TimelineStayManagementDrawerFormFieldEnum.IsOverNightStayRequired
        ].value,
      sector: sector?.name
    } as formData"
  >
    <div class="mb-2">
      <wilson-find-hotel
        [locationId]="
          form.controls[TimelineStayManagementDrawerFormFieldEnum.LocationId]
            .value
        "
        [checkInDatetime]="
          form.controls[
            TimelineStayManagementDrawerFormFieldEnum.CheckInDateTime
          ].value
        "
        [checkOutDatetime]="
          form.controls[
            TimelineStayManagementDrawerFormFieldEnum.CheckOutDateTime
          ].value
        "
      ></wilson-find-hotel>
    </div>

    <div class="mb-2">
      <div
        [ngClass]="{
          'text-danger':
            form.controls[TimelineStayManagementDrawerFormFieldEnum.LocationId]
            | formControlInvalid
        }"
        class="title-text align-items-center"
      >
        {{ 'general.location' | translate }}
      </div>
      <wilson-location-selects
        [sector]="formData.sector"
        [invalid]="
          form.controls[TimelineStayManagementDrawerFormFieldEnum.LocationId]
            .touched &&
          !form.controls[TimelineStayManagementDrawerFormFieldEnum.LocationId]
            .value
        "
        [selectedLocation]="previousShiftLocation$ | async"
        [readonly]="formData.isFormEditable"
        [ignoreFeatureFlag]="true"
        (locationChange)="setLocationId($event)"
        (touched)="
          form.controls[
            TimelineStayManagementDrawerFormFieldEnum.LocationId
          ].markAsTouched()
        "
      ></wilson-location-selects>
    </div>

    <div class="mb-2">
      <div
        [ngClass]="{
          'text-danger':
            form.controls[TimelineStayManagementDrawerFormFieldEnum.StayStatus]
            | formControlInvalid
        }"
        class="d-flex align-items-center title-text"
      >
        {{
          ('page.shift_timeline.hotel_stay.drawer.form.stay_status'
            | translate) + '*'
        }}
      </div>

      <wilson-timeline-stays-status
        [selectedStayStatus]="
          form.controls[TimelineStayManagementDrawerFormFieldEnum.StayStatus]
            .value
        "
        [isDisabled]="formData.isFormEditable"
        [showCancelledStatus]="showCancelledStatus"
        (changedStatus)="updateFormSelectedStayStatus($event)"
      ></wilson-timeline-stays-status>
    </div>

    <div class="mb-2">
      <div
        [ngClass]="{
          'text-danger':
            form.controls[TimelineStayManagementDrawerFormFieldEnum.StayStatus]
            | formControlInvalid
        }"
        class="d-flex align-items-center title-text"
      >
        {{ ('general.users' | translate) + '*' }}
      </div>
      <wilson-users-select
        [hideLabel]="true"
        [formControlName]="TimelineStayManagementDrawerFormFieldEnum.UserId"
      ></wilson-users-select>
    </div>

    <div class="mt-1">
      <div class="d-flex align-items-center title-text">
        {{
          'page.shift_timeline.hotel_stay.drawer.form.accomodation_type'
            | translate
        }}
      </div>
      <nz-segmented
        [nzDisabled]="formData.isFormEditable"
        [nzBlock]="true"
        [ngModel]="selectedStayTypeIndex"
        [nzOptions]="segmentOptions"
        [ngModelOptions]="{ standalone: true }"
        (nzValueChange)="updateFormStateAndSelectedOption($event)"
      ></nz-segmented>
    </div>

    <div
      [ngClass]="{
        'text-danger':
          form.controls[TimelineStayManagementDrawerFormFieldEnum.TimeFrame]
          | formControlInvalid
      }"
      class="d-flex align-items-center title-text mt-2"
    >
      {{ 'general.timeframe' | translate }}
    </div>
    <nz-form-control
      [nzValidateStatus]="
        (form.controls[TimelineStayManagementDrawerFormFieldEnum.TimeFrame]
        | formControlInvalid)
          ? 'error'
          : 'success'
      "
    >
      <nz-range-picker
        [nzPlaceHolder]="['', '']"
        formControlName="timeFrame"
        id="dateRange"
        nzFormat="dd.MM.yyyy"
      ></nz-range-picker>
    </nz-form-control>

    <div class="d-flex gap-1">
      <div class="col-5 w-50">
        <div
          [ngClass]="{
            'text-danger':
              form.controls[
                TimelineStayManagementDrawerFormFieldEnum.CheckInDateTime
              ] | formControlInvalid
          }"
          class="d-flex align-items-center title-text mt-2"
        >
          {{
            'page.shift_timeline.hotel_stay.drawer.form.check_in' | translate
          }}
        </div>
        <nz-form-item class="mb-0">
          <nz-form-control>
            <nz-time-picker
              class="w-45"
              formControlName="checkInDateTime"
              [nzFormat]="'HH:mm'"
            ></nz-time-picker>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div class="col-5 w-50">
        <div
          [ngClass]="{
            'text-danger':
              form.controls[
                TimelineStayManagementDrawerFormFieldEnum.CheckOutDateTime
              ] | formControlInvalid
          }"
          class="d-flex align-items-center title-text mt-2"
        >
          {{
            'page.shift_timeline.hotel_stay.drawer.form.check_out' | translate
          }}
        </div>
        <nz-form-item class="right-column mb-0">
          <nz-form-control>
            <nz-time-picker
              class="w-45"
              formControlName="checkOutDateTime"
              [nzFormat]="'HH:mm'"
            ></nz-time-picker>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <div class="d-flex align-items-center title-text mt-2">
      {{
        'page.shift_timeline.hotel_stay.drawer.form.accomodation_name'
          | translate
      }}
    </div>
    <wilson-accomodation-prefill
      [locationId]="
        form.controls[TimelineStayManagementDrawerFormFieldEnum.LocationId]
          .value
      "
      [accomodationName]="
        form.controls[
          TimelineStayManagementDrawerFormFieldEnum.AccomodationName
        ].value
      "
      [disabled]="formData.isFormEditable"
      (selectedStay)="updateFormValues($event)"
      (userInput)="setAccomodationName($event)"
    ></wilson-accomodation-prefill>

    <div class="d-flex gap-1 mt-2">
      <div class="col-5 w-50">
        <div
          [ngClass]="{
            'text-danger':
              form.controls[
                TimelineStayManagementDrawerFormFieldEnum.PriceTotal
              ] | formControlInvalid
          }"
          class="d-flex align-items-center title-text"
        >
          {{ 'page.shift_timeline.hotel_stay.drawer.form.price' | translate }}
        </div>
        <nz-form-item class="mb-0">
          <nz-form-control>
            <nz-input-number
              [nzStep]="1"
              [nzMin]="0"
              class="w-100"
              formControlName="priceTotal"
            ></nz-input-number>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div class="col-5 w-50">
        <div
          [ngClass]="{
            'text-danger':
              form.controls[
                TimelineStayManagementDrawerFormFieldEnum.PaidNights
              ] | formControlInvalid
          }"
          class="d-flex align-items-center title-text"
        >
          {{
            'page.shift_timeline.hotel_stay.drawer.form.paid_nights' | translate
          }}
        </div>
        <nz-form-item class="right-column mb-0">
          <nz-input-number
            [nzStep]="1"
            [nzMin]="0"
            class="w-100"
            formControlName="paidNights"
          ></nz-input-number>
        </nz-form-item>
      </div>
    </div>

    <div class="d-flex align-items-center title-text mt-2">
      {{ 'page.shift_timeline.stay.standard_rate' | translate }}
    </div>
    <nz-form-item class="mb-0">
      <nz-form-control>
        <nz-input-number
          [nzStep]="1"
          [nzMin]="0"
          class="w-100"
          [nzPlaceHolder]="'page.shift_timeline.stay.standard_rate' | translate"
          formControlName="standardRate"
        ></nz-input-number>
      </nz-form-control>
    </nz-form-item>

    <div class="d-flex align-items-center title-text mt-2">
      {{
        'page.shift_timeline.hotel_stay.drawer.form.cancellation_date'
          | translate
      }}
    </div>
    <nz-form-control>
      <nz-date-picker
        [nzShowTime]="{ nzFormat: DateTimeFormat.TimeFormat }"
        [nzFormat]="
          DateTimeFormat.GermanDateFormat + ', ' + DateTimeFormat.TimeFormat
        "
        formControlName="cancellationDate"
      ></nz-date-picker>
    </nz-form-control>

    <div class="d-flex align-items-center title-text mt-2">
      {{ 'address' | translate }}
    </div>

    <wilson-location-selects
      [placeholder]="
        form.controls[TimelineStayManagementDrawerFormFieldEnum.Address]
          .value ?? ''
      "
      [sector]="formData.sector"
      [ignoreFeatureFlag]="true"
      [readonly]="formData.isFormEditable"
      (locationChange)="setAddress($event)"
    ></wilson-location-selects>

    <div class="mt-2 mb-2">
      <div class="d-flex align-items-center title-text">
        {{ 'page.shift_timeline.stay.email' | translate }}
      </div>
      <input
        nz-input
        type="text"
        [placeholder]="'page.shift_timeline.stay.email' | translate"
        formControlName="email"
      />
    </div>

    <div class="mt-2 mb-2">
      <div class="d-flex align-items-center title-text">
        {{ 'page.shift_timeline.hotel_stay.drawer.form.telephone' | translate }}
      </div>
      <input
        nz-input
        type="tel"
        [placeholder]="
          'page.shift_timeline.hotel_stay.drawer.form.telephone_placeholder'
            | translate
        "
        formControlName="phoneNumber"
      />
    </div>

    <div class="mt-2 mb-1">
      <div class="d-flex align-items-center title-text">
        {{ 'general.notes' | translate }}
      </div>
      <nz-textarea-count [nzMaxCharacterCount]="420">
        <textarea
          formControlName="note"
          nz-input
          [placeholder]="'general.add_comment' | translate"
          type="text"
          id="note"
          rows="4"
        ></textarea>
      </nz-textarea-count>
    </div>

    <nz-collapse [nzBordered]="false" nzGhost class="mt-2">
      <nz-collapse-panel
        #invoicePanel
        class="collapse-panel"
        [nzHeader]="invoiceSectionHeader"
        [nzActive]="false"
        [nzShowArrow]="false"
        [nzExtra]="invoiceCollapseIcon"
      >
        <div class="mb-1">
          <wilson-stay-attachments
            [isSingleSelection]="true"
            [stayAttachments]="invoiceAttachments"
            (updatedAttachments)="handleInvoice.emit($event)"
          ></wilson-stay-attachments>
        </div>

        <div class="mt-2 mb-2">
          <div class="d-flex align-items-center title-text">
            {{ 'page.shift_timeline.stay.invoice_reference' | translate }}
          </div>
          <input
            nz-input
            type="text"
            [placeholder]="
              'page.shift_timeline.stay.invoice_reference' | translate
            "
            formControlName="invoiceReference"
          />
        </div>

        <div class="mt-2 mb-2">
          <div class="d-flex align-items-center title-text">
            {{ 'page.shift_timeline.stay.creditor' | translate }}
          </div>
          <input
            nz-input
            type="text"
            [placeholder]="'page.shift_timeline.stay.creditor' | translate"
            formControlName="creditor"
          />
        </div>

        <div class="mt-2 mb-2">
          <div class="d-flex align-items-center title-text">
            {{ 'page.shift_timeline.stay.cost_center' | translate }}
          </div>
          <input
            nz-input
            type="text"
            [placeholder]="'page.shift_timeline.stay.cost_center' | translate"
            formControlName="costCenter"
          />
        </div>
      </nz-collapse-panel>
      <ng-template #invoiceSectionHeader>
        <span class="d-flex align-items-center title-text">
          {{ 'page.shift_timeline.stay.invoice' | translate }}
        </span>
      </ng-template>
      <ng-template #invoiceCollapseIcon>
        <i
          class="fas fa-chevron-up collapse-icon"
          [ngClass]="invoicePanel.nzActive ? 'rotate-0' : 'rotate-180'"
        ></i>
      </ng-template>
    </nz-collapse>

    <nz-collapse [nzBordered]="false" nzGhost class="mt-2">
      <nz-collapse-panel
        #attachmentsPanel
        class="collapse-panel"
        [nzHeader]="attachmentsSectionHeader"
        [nzActive]="false"
        [nzShowArrow]="false"
        [nzExtra]="attachmentsCollapseIcon"
      >
        <div class="mb-1">
          <wilson-stay-attachments
            [stayAttachments]="otherAttachments"
            (updatedAttachments)="handleAttachments.emit($event)"
          ></wilson-stay-attachments>
        </div>
      </nz-collapse-panel>
      <ng-template #attachmentsSectionHeader>
        <span class="d-flex align-items-center title-text">
          {{ 'page.shift_timeline.stay.attachments' | translate }}
        </span>
      </ng-template>
      <ng-template #attachmentsCollapseIcon>
        <i
          class="fas fa-chevron-up collapse-icon"
          [ngClass]="attachmentsPanel.nzActive ? 'rotate-0' : 'rotate-180'"
        ></i>
      </ng-template>
    </nz-collapse>
  </ng-container>
</form>
}
