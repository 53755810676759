import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ShiftTimelineDrawerStateModel } from '../interfaces';

import {
  ResetTimelineDrawerState,
  SelectedTimelineShiftId,
  ToggleShiftTimelineDrawerVisibility,
} from './shift-timeline-drawer.actions';

export const shiftTimelineDrawerDefaultData: ShiftTimelineDrawerStateModel = {
  isDrawerVisible: false,
  selectedShiftId: '',
  isSelectedShiftUnAssigned: false,
};

@State<ShiftTimelineDrawerStateModel>({
  name: 'shiftTimelineDrawer',
  defaults: shiftTimelineDrawerDefaultData,
})
@Injectable()
export class ShiftTimelineDrawerState {
  @Selector()
  static isDrawerVisible(state: ShiftTimelineDrawerStateModel) {
    return state.isDrawerVisible;
  }

  @Selector()
  static selectedShiftId(state: ShiftTimelineDrawerStateModel) {
    return state.selectedShiftId;
  }

  @Selector()
  static isUnAssignedShift(state: ShiftTimelineDrawerStateModel) {
    return state.isSelectedShiftUnAssigned;
  }

  @Action(ToggleShiftTimelineDrawerVisibility)
  toggleDrawerVisibility(
    { patchState }: StateContext<ShiftTimelineDrawerStateModel>,
    { payload }: ToggleShiftTimelineDrawerVisibility,
  ) {
    patchState({ isDrawerVisible: payload });
  }

  @Action(SelectedTimelineShiftId)
  selectedTimelineShift(
    ctx: StateContext<ShiftTimelineDrawerStateModel>,
    action: SelectedTimelineShiftId,
  ) {
    ctx.patchState({
      selectedShiftId: action.shiftId,
      isSelectedShiftUnAssigned: action.isUnAssignedShift,
    });
  }

  @Action(ResetTimelineDrawerState)
  resetDrawerState({
    patchState,
  }: StateContext<ShiftTimelineDrawerStateModel>) {
    patchState(shiftTimelineDrawerDefaultData);
  }
}
