import { addDays, eachDayOfInterval, endOfDay, startOfDay } from 'date-fns';
import {
  ShiftTimelineDataStateModel,
  ShiftTimelineFilterStateModel,
  TimelineDataUtilityStateModel,
} from './interfaces';
import { AssignmentAvailabilityStatus } from '@wilson/interfaces';

const defaultNumberOfDaysInDatepicker = 6;
const defaultTimeFrame = {
  start: startOfDay(new Date()),
  end: endOfDay(addDays(new Date(), defaultNumberOfDaysInDatepicker)),
};

export const shiftTimelineDefaultData: ShiftTimelineDataStateModel = {
  loadingActivitiesForShiftIds: [],
  visibleDateRange: {
    start: null,
    end: null,
  },
  currentDate: null,
  usersDictionary: {},
  filterUserIds: [],
  determinedShiftActivities: {},
  usersValidationRecords: {},
  validatedUserRecords: {},
  usersOvertimes: [],
  isLoadingUsers: false,
  isLoadingFilteredUsers: false,
  isRefreshingUsers: false,
  isLoadingUsersOvertimes: false,
  hasSuccessfullyFetchedUsersOvertimes: false,
  numberOfDaysInDatepicker: defaultNumberOfDaysInDatepicker,
  timeframe: defaultTimeFrame,
  daysRange: eachDayOfInterval(defaultTimeFrame),
  isLoadingValidations: false,
  isLoadingValidationsError: false,
  isLoadingValidationsV1Error: false,
  currentTimelineHeightPx: 0,
  usersWithUpdatingValidations: [],
  shiftAssignmentDataRecordsV2: {},
  usersWithUpdatingShiftAssignments: [],
  hasSuccessfullyFetchedUsers: false,
  shiftIdsToHighlight: [],
  stayIdsToHighlight: [],
  serviceIdsToHighlight: [],
  userIdsToPinOnTimeline: [],
  dateToUserIdAssignmentIdRecord: {},
};

export const shiftTimelineDefaultFilter: ShiftTimelineFilterStateModel = {
  filters: {
    orgUnit: [],
    users: [],
    role: [],
    absences: [],
    shiftCategory: [],
    releaseStatus: null,
    searchTerm: null,
    showOnlyQualifiedStaff: false,
    showOnlyQualifiedWorkingTimeStaff: false,
    priority: [],
    profession: [],
    staffAvailability: AssignmentAvailabilityStatus.Available,
    labels: [],
    projects: [],
    confirmedDeclined: [],
  },
};

export const defaultTimelineDataUtilityState: TimelineDataUtilityStateModel = {
  unreleasedShifts: [],
  declinedShifts: [],
  isLoadingUnreleasedShifts: false,
  productivitiesResponse: {
    usersProductivities: {},
    overallProductivity: {
      productiveMinutes: 0,
      totalMinutes: 0,
      productivityRatio: 0,
    },
  },
  isLoadingUsersProductivity: true,
  loadingProductivityUserIds: [],
  hasFetchedUsersProductivitySuccessfully: false,
};
