import { Injectable, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  Action,
  Selector,
  State,
  StateContext,
  Store,
  createSelector,
} from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import {
  Activity,
  ActivityRow,
  NumberOfShiftPerDay,
  NumberOfShiftPerDayRecord,
  UnassignedShift,
} from '@wilson/interfaces';
import { TabEnum } from '@wilson/shift-timeline/services';
import { cloneDeep } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import {
  Observable,
  Subject,
  catchError,
  from,
  map,
  takeUntil,
  tap,
  switchMap,
} from 'rxjs';
import {
  ShiftId,
  ShiftTimelineUnassignedRegionStateModel,
  UnassignedDateToShiftRecords,
} from '../interfaces';
import { ActivitiesStateLoaderService } from '../services/activities-state-loader.service';
import { ShiftTimelineDataState } from '../shift-timeline-data/shift-timeline-data.state';
import {
  AddActivityToUnassignedRegion,
  AddShiftTemplateToSuggestionList,
  AddShiftWithoutActivitiesToCellViewUnassignedRegion,
  AddShiftWithoutActivitiesToUnassignedRegion,
  BulkAddShiftActivitiesToUnassignedRegion,
  BulkDeleteShiftActivitiesFromUnassignedRegion,
  ClearAllUnassignedActivitiesRelatedData,
  ClearShiftActivitiesRows,
  ClearUnassignedShiftsWithoutActivities,
  FetchActivitiesForUnassignedShifts,
  HideLoaderWhenLoadingUnassignedShiftsOrActivities,
  RemoveActivityFromUnassignedRegion,
  RemoveShiftWithoutActivitiesFromCellViewUnassignedRegion,
  RemoveShiftWithoutActivitiesFromUnassignedRegion,
  SetSelectedUnassignedRegion,
  TimelineFetchUnassignedShiftsWithoutActivities,
  TimelineRemoveActivityFromUnassignedRegion,
  TimelineUpdateCellViewUnassignedRegionShiftsCount,
  TimelineUpdateCellViewUnassignedShiftsRegionWithoutActivities,
  TimelineUpdateStandardViewUnassignedShiftsRegionWithoutActivities,
  TimelineUpdateUnassignedActivities,
  TimelineUpdateUnassignedShiftsRegionWithoutActivities,
  TimelineUpsertActivityToUnassignedRegion,
  ToggleExpandUnassignedRegion,
  UpdateShiftWithoutActivitiesFromCellViewUnassignedRegion,
  UpdateShiftWithoutActivitiesFromUnassignedRegion,
} from './shift-timeline-unassigned-region.actions';
import { TimelineFetchUnassignedActivitiesActionService } from './timeline-fetch-unassigned-activities-action.service';
import { eachDayOfInterval, startOfDay } from 'date-fns';
import { getStartDatetime } from '@wilson/non-domain-specific/overlap-helpers';
import { determineShiftRenderDatetime } from '@wilson/shift-timeline/services';
import { UserTimelinesGateway } from '@wilson/api/gateway';
import { ShiftTimelineFilterStateService } from '../shift-timeline-filters/shift-timeline-filter-state.service';
import { getTimeZoneCorrectedDateRange } from '@wilson/utils';

const defaults: ShiftTimelineUnassignedRegionStateModel = {
  loadingActivitiesForShiftIds: [],
  selectedRegion: TabEnum.UnassignedShifts,
  shiftActivitiesRows: {},
  unassignedShiftsWithoutActivities: {},
  suggestedShiftTemplates: [],
  unassignedActivities: [],
  unassignedServices: [],
  unassignedJobs: [],
  isLoadingUnassignedShifts: false,
  unassignedActivitiesViewRows: [],
  hideLoaderWhenLoadingUnassignedShiftsOrActivities: false,
  isLoadingUnassignedActivities: false,
  isUnassignedRegionExpanded: false,
  isRefreshingUnassignedShifts: false,
  isRefreshingUnassignedActivities: false,
  unassignedDateToShiftRecords: {},
  numberOfShiftPerDayRecord: {},
};

@State<ShiftTimelineUnassignedRegionStateModel>({
  name: 'shiftTimelineUnassignedRegion',
  defaults,
})
@Injectable()
export class ShiftTimelineUnassignedRegionState {
  private unsubRequestForUnassignedShiftsSubject = new Subject();
  private store = inject(Store);
  private toastrService = inject(ToastrService);
  private translateService = inject(TranslateService);
  private timelineFetchUnassignedActivitiesActionService = inject(
    TimelineFetchUnassignedActivitiesActionService,
  );
  private userTimelinesGateway = inject(UserTimelinesGateway);
  private shiftTimelineFilterStateService = inject(
    ShiftTimelineFilterStateService,
  );

  constructor(
    private activitiesStateLoaderService: ActivitiesStateLoaderService,
  ) {}

  @Selector()
  static isRefreshingUnassignedShifts(
    state: ShiftTimelineUnassignedRegionStateModel,
  ) {
    return state.isRefreshingUnassignedShifts;
  }

  @Selector()
  static isRefreshingUnassignedActivities(
    state: ShiftTimelineUnassignedRegionStateModel,
  ) {
    return state.isRefreshingUnassignedActivities;
  }

  @Selector()
  static unassignedShiftsWithoutActivities(
    state: ShiftTimelineUnassignedRegionStateModel,
  ) {
    return state.unassignedShiftsWithoutActivities;
  }

  @Selector()
  static unassignedDateToShiftRecords(
    state: ShiftTimelineUnassignedRegionStateModel,
  ) {
    return state.unassignedDateToShiftRecords;
  }

  @Selector()
  static numberOfShiftPerDayRecords(
    state: ShiftTimelineUnassignedRegionStateModel,
  ) {
    return state.numberOfShiftPerDayRecord;
  }

  @Selector()
  static suggestedShiftTemplates(
    state: ShiftTimelineUnassignedRegionStateModel,
  ) {
    return state.suggestedShiftTemplates;
  }

  @Selector()
  static unassignedActivities(state: ShiftTimelineUnassignedRegionStateModel) {
    return state.unassignedActivities;
  }

  @Selector()
  static unassignedActivitiesLength(
    state: ShiftTimelineUnassignedRegionStateModel,
  ) {
    return state.unassignedActivities.length;
  }

  @Selector([
    ShiftTimelineUnassignedRegionState.unassignedDateToShiftRecords,
    ShiftTimelineDataState.visibleDateRange,
  ])
  static unassignedShiftStacksInView(
    unassignedDateToShiftRecords: UnassignedDateToShiftRecords,
    timeFrame: { start: Date | null; end: Date | null },
  ) {
    if (!timeFrame.start || !timeFrame.end) {
      return [];
    } else {
      return eachDayOfInterval({
        start: timeFrame.start as Date,
        end: timeFrame.end as Date,
      }).map((day) => {
        return {
          day,
          shifts:
            unassignedDateToShiftRecords[
              ShiftTimelineUnassignedRegionState.getUnassignedDateToShiftKey(
                day,
              )
            ] || [],
        };
      });
    }
  }

  @Selector([
    ShiftTimelineUnassignedRegionState.unassignedShiftsWithoutActivities,
    ShiftTimelineDataState.visibleDateRange,
  ])
  static unassignedShiftsInView(
    shifts: Record<string, UnassignedShift>,
    timeFrame: { start: Date | null; end: Date | null },
  ): UnassignedShift[] {
    if (!timeFrame.start || !timeFrame.end) {
      return [];
    } else {
      return Object.values(shifts).filter((shift) => {
        const shiftStartDate = new Date(shift.startDatetime);
        const shiftEndDate = new Date(shift.endDatetime);
        return (
          (shiftStartDate >= (timeFrame.start as Date) &&
            shiftStartDate <= (timeFrame.end as Date)) ||
          (shiftEndDate >= (timeFrame.start as Date) &&
            shiftEndDate <= (timeFrame.end as Date))
        );
      });
    }
  }

  @Selector()
  static isUnassignedRegionExpanded(
    state: ShiftTimelineUnassignedRegionStateModel,
  ) {
    return state.isUnassignedRegionExpanded;
  }

  @Selector()
  static isLoadingUnassignedShifts(
    state: ShiftTimelineUnassignedRegionStateModel,
  ) {
    return state.isLoadingUnassignedShifts;
  }

  @Selector()
  static hideLoaderWhenLoadingUnassignedShiftsOrActivities(
    state: ShiftTimelineUnassignedRegionStateModel,
  ) {
    return state.hideLoaderWhenLoadingUnassignedShiftsOrActivities;
  }

  @Selector()
  static isLoadingUnassignedActivities(
    state: ShiftTimelineUnassignedRegionStateModel,
  ) {
    return state.isLoadingUnassignedActivities;
  }

  @Selector()
  static unassignedActivitiesViewRows(
    state: ShiftTimelineUnassignedRegionStateModel,
  ) {
    return state.unassignedActivitiesViewRows;
  }

  @Selector()
  static unassignedServices(state: ShiftTimelineUnassignedRegionStateModel) {
    return state.unassignedServices;
  }

  @Selector()
  static unassignedJobs(state: ShiftTimelineUnassignedRegionStateModel) {
    return state.unassignedJobs;
  }

  @Selector()
  static shiftActivitiesRows(state: ShiftTimelineUnassignedRegionStateModel) {
    return state.shiftActivitiesRows;
  }

  @Selector()
  static selectedRegion(state: ShiftTimelineUnassignedRegionStateModel) {
    return state.selectedRegion;
  }

  static shiftActivitiesRowsOf(shiftId: string) {
    return createSelector(
      [ShiftTimelineUnassignedRegionState.shiftActivitiesRows],
      (rowsMap) => {
        return rowsMap[shiftId];
      },
    );
  }

  static unassignedShiftActivitiesArray(shiftId: string) {
    return createSelector(
      [ShiftTimelineUnassignedRegionState.shiftActivitiesRowsOf(shiftId)],
      (activityRows: ActivityRow[]) => {
        return activityRows.flatMap((row) => Object.values(row));
      },
    );
  }

  static getService(serviceId: string) {
    return createSelector(
      [ShiftTimelineUnassignedRegionState.unassignedServices],
      (unassignedServices) => {
        const service = unassignedServices.find(
          (service) => service.id === serviceId,
        );
        return service ? service : null;
      },
    );
  }

  static getServiceName(serviceId: string) {
    return createSelector(
      [ShiftTimelineUnassignedRegionState.unassignedServices],
      (unassignedServices) => {
        const service = unassignedServices.find(
          (service) => service.id === serviceId,
        );
        return service ? service.name : '';
      },
    );
  }

  static getJobName(jobId: string) {
    return createSelector(
      [ShiftTimelineUnassignedRegionState.unassignedJobs],
      (unassignedJobs) => {
        const job = unassignedJobs.find((job) => job.id === jobId);
        return job ? job.name : '';
      },
    );
  }

  static getShiftWithoutActivities(shiftId: string) {
    return createSelector(
      [ShiftTimelineUnassignedRegionState.unassignedShiftsWithoutActivities],
      (unassignedShifts) => {
        return Object.values(unassignedShifts).find(
          (shift) => shift.id === shiftId,
        );
      },
    );
  }

  static getCellViewShiftWithoutActivities(shiftId: string) {
    return createSelector(
      [ShiftTimelineUnassignedRegionState.unassignedDateToShiftRecords],
      (unassignedDateToShiftRecords) => {
        return this.getShiftFromUnassignedDateRecords(
          unassignedDateToShiftRecords,
          shiftId,
        );
      },
    );
  }

  static getCellViewShiftWithoutActivitiesCount(day: Date) {
    const startDateTime =
      ShiftTimelineUnassignedRegionState.getUnassignedDateToShiftKey(day);

    return createSelector(
      [ShiftTimelineUnassignedRegionState.numberOfShiftPerDayRecords],
      (numberOfShiftPerDayRecords) => {
        return numberOfShiftPerDayRecords[startDateTime] || 0;
      },
    );
  }

  @Action(ClearShiftActivitiesRows)
  clearDeterminedShiftActivities(
    ctx: StateContext<ShiftTimelineUnassignedRegionStateModel>,
  ) {
    ctx.patchState({
      shiftActivitiesRows: {},
    });
  }

  @Action(ClearUnassignedShiftsWithoutActivities)
  clearUnassignedShiftsWithoutActivities(
    ctx: StateContext<ShiftTimelineUnassignedRegionStateModel>,
  ) {
    ctx.patchState({
      unassignedShiftsWithoutActivities: {},
    });
  }

  @Action(ClearAllUnassignedActivitiesRelatedData)
  clearAllUnassignedActivitiesRelatedData(
    ctx: StateContext<ShiftTimelineUnassignedRegionStateModel>,
  ) {
    ctx.patchState({
      unassignedActivities: [],
      unassignedJobs: [],
      unassignedServices: [],
      unassignedActivitiesViewRows: [],
    });
  }

  @Action(SetSelectedUnassignedRegion)
  setUnassignedRegion(
    { patchState }: StateContext<ShiftTimelineUnassignedRegionStateModel>,
    { payload }: SetSelectedUnassignedRegion,
  ) {
    patchState({
      selectedRegion: payload,
    });
  }

  @Action(ToggleExpandUnassignedRegion)
  toggleExpandUnassignedRegion(
    { patchState }: StateContext<ShiftTimelineUnassignedRegionStateModel>,
    { payload }: ToggleExpandUnassignedRegion,
  ) {
    const updatePayload: Pick<
      ShiftTimelineUnassignedRegionStateModel,
      'isUnassignedRegionExpanded'
    > = {
      isUnassignedRegionExpanded: payload,
    };
    patchState(updatePayload);
  }

  @Action(HideLoaderWhenLoadingUnassignedShiftsOrActivities)
  hideLoaderWhenLoadingUnassignedShiftsOrActivities(
    { patchState }: StateContext<ShiftTimelineUnassignedRegionStateModel>,
    { payload }: HideLoaderWhenLoadingUnassignedShiftsOrActivities,
  ) {
    const updatePayload: Pick<
      ShiftTimelineUnassignedRegionStateModel,
      'hideLoaderWhenLoadingUnassignedShiftsOrActivities'
    > = {
      hideLoaderWhenLoadingUnassignedShiftsOrActivities: payload,
    };
    patchState(updatePayload);
  }

  static getUnassignedDateToShiftKey(date: string | Date) {
    return startOfDay(new Date(date)).toISOString();
  }

  @Action(TimelineFetchUnassignedShiftsWithoutActivities)
  fetchUnassignedShiftsWithoutActivities(
    ctx: StateContext<ShiftTimelineUnassignedRegionStateModel>,
  ) {
    const timeFrame = this.store.selectSnapshot(
      ShiftTimelineDataState.timeframe,
    );

    ctx.patchState({
      isLoadingUnassignedShifts: true,
      isUnassignedRegionExpanded: false,
      hideLoaderWhenLoadingUnassignedShiftsOrActivities: false,
    });

    return this.fetchUnassignedShiftsAndPrepareDataForStandardView(
      timeFrame,
    ).pipe(
      tap((transformedShifts) => {
        ctx.patchState({
          unassignedShiftsWithoutActivities: transformedShifts,
          isLoadingUnassignedShifts: false,
          hideLoaderWhenLoadingUnassignedShiftsOrActivities: false,
        });
      }),
      catchError(() => {
        this.toastrService.error(
          this.translateService.instant('general.something_went_wrong'),
        );
        return [];
      }),
    );
  }

  @Action(TimelineUpdateUnassignedShiftsRegionWithoutActivities)
  updateUnassignedActivitiesWithoutActivities(
    ctx: StateContext<ShiftTimelineUnassignedRegionStateModel>,
    action: TimelineUpdateUnassignedShiftsRegionWithoutActivities,
  ) {
    ctx.patchState({
      isRefreshingUnassignedShifts: true,
    });
    return this.fetchUnassignedShiftsAndPrepareDataForStandardView(
      action.timeFrame,
    ).pipe(
      tap((transformedShifts) => {
        ctx.setState(
          patch({
            unassignedShiftsWithoutActivities: transformedShifts,
            isLoadingUnassignedShifts: false,
            isRefreshingUnassignedShifts: false,
          }),
        );
      }),
      catchError(() => {
        this.toastrService.error(
          this.translateService.instant('general.something_went_wrong'),
        );
        return [];
      }),
    );
  }

  @Action(TimelineUpdateCellViewUnassignedRegionShiftsCount)
  timelineUpdateCellViewUnassignedRegionShiftsCount(
    ctx: StateContext<ShiftTimelineUnassignedRegionStateModel>,
    action: TimelineUpdateCellViewUnassignedRegionShiftsCount,
  ) {
    ctx.patchState({
      isRefreshingUnassignedShifts: true,
    });
    return this.fetchUnassignedShiftsCountPerDay(action.timeFrame).pipe(
      tap((numberOfShiftPerDayRecord) => {
        ctx.setState(
          patch({
            numberOfShiftPerDayRecord,
            isLoadingUnassignedShifts: false,
            isRefreshingUnassignedShifts: false,
          }),
        );
      }),
      catchError(() => {
        this.toastrService.error(
          this.translateService.instant('general.something_went_wrong'),
        );
        return [];
      }),
    );
  }

  @Action(TimelineUpdateCellViewUnassignedShiftsRegionWithoutActivities)
  timelineUpdateCellViewUnassignedShiftsRegionWithoutActivities(
    ctx: StateContext<ShiftTimelineUnassignedRegionStateModel>,
    action: TimelineUpdateCellViewUnassignedShiftsRegionWithoutActivities,
  ) {
    ctx.patchState({
      isRefreshingUnassignedShifts: true,
    });
    return this.fetchUnassignedShiftsAndPrepareDataForCellView(
      action.timeFrame,
    ).pipe(
      tap((unassignedDateToShiftRecords) => {
        ctx.setState(
          patch({
            unassignedDateToShiftRecords,
            isLoadingUnassignedShifts: false,
            isRefreshingUnassignedShifts: false,
          }),
        );
      }),
      catchError(() => {
        this.toastrService.error(
          this.translateService.instant('general.something_went_wrong'),
        );
        return [];
      }),
    );
  }

  @Action(TimelineUpdateStandardViewUnassignedShiftsRegionWithoutActivities)
  timelineUpdateStandardViewUnassignedShiftsRegionWithoutActivities(
    ctx: StateContext<ShiftTimelineUnassignedRegionStateModel>,
    action: TimelineUpdateUnassignedShiftsRegionWithoutActivities,
  ) {
    ctx.patchState({
      isRefreshingUnassignedShifts: true,
    });
    return this.fetchUnassignedShiftsAndPrepareDataForStandardView(
      action.timeFrame,
    ).pipe(
      tap((transformedShifts) => {
        ctx.setState(
          patch({
            unassignedShiftsWithoutActivities: transformedShifts,
            isLoadingUnassignedShifts: false,
            isRefreshingUnassignedShifts: false,
          }),
        );
      }),
      catchError(() => {
        this.toastrService.error(
          this.translateService.instant('general.something_went_wrong'),
        );
        return [];
      }),
    );
  }

  @Action(TimelineUpdateUnassignedActivities)
  timelineUpdateUnassignedActivities(
    ctx: StateContext<ShiftTimelineUnassignedRegionStateModel>,
    action: TimelineUpdateUnassignedActivities,
  ) {
    return this.timelineFetchUnassignedActivitiesActionService.handleUpdate(
      ctx,
      action,
    );
  }

  @Action(UpdateShiftWithoutActivitiesFromCellViewUnassignedRegion)
  updateCellViewShiftWithoutActivitiesFromUnassignedRegion(
    ctx: StateContext<ShiftTimelineUnassignedRegionStateModel>,
    action: UpdateShiftWithoutActivitiesFromCellViewUnassignedRegion,
  ) {
    const state = ctx.getState();
    const unassignedDateToShiftRecordsCopy = {
      ...state.unassignedDateToShiftRecords,
    };
    const startDateTime =
      ShiftTimelineUnassignedRegionState.getUnassignedDateToShiftKey(
        determineShiftRenderDatetime(action.shift).startDatetime.date,
      );

    if (startDateTime in unassignedDateToShiftRecordsCopy) {
      ctx.setState(
        patch({
          unassignedDateToShiftRecords: patch({
            [startDateTime]: patch({
              [action.shift.id]: action.shift,
            }),
          }),
        }),
      );
    }
  }

  @Action(UpdateShiftWithoutActivitiesFromUnassignedRegion)
  updateShiftWithoutActivitiesFromUnassignedRegion(
    ctx: StateContext<ShiftTimelineUnassignedRegionStateModel>,
    action: UpdateShiftWithoutActivitiesFromUnassignedRegion,
  ) {
    ctx.setState(
      patch({
        unassignedShiftsWithoutActivities: patch({
          [action.shift.id as string]: action.shift,
        }),
      }),
    );
  }

  @Action(RemoveShiftWithoutActivitiesFromCellViewUnassignedRegion)
  removeCellViewShiftWithoutActivitiesFromUnassignedRegion(
    ctx: StateContext<ShiftTimelineUnassignedRegionStateModel>,
    action: RemoveShiftWithoutActivitiesFromCellViewUnassignedRegion,
  ) {
    const state = ctx.getState();
    const clonedUnassignedDateToShiftRecords = {
      ...state.unassignedDateToShiftRecords,
    };
    const shift =
      ShiftTimelineUnassignedRegionState.getShiftFromUnassignedDateRecords(
        clonedUnassignedDateToShiftRecords,
        action.payload.shiftId,
      );

    if (shift) {
      const startDateTime =
        ShiftTimelineUnassignedRegionState.getUnassignedDateToShiftKey(
          determineShiftRenderDatetime(shift).startDatetime.date,
        );

      const stackShifts = {
        ...clonedUnassignedDateToShiftRecords[startDateTime],
      };

      delete stackShifts[action.payload.shiftId];

      ctx.setState(
        patch({
          unassignedDateToShiftRecords: patch({
            [startDateTime]: stackShifts,
          }),
        }),
      );
    } else {
      this.toastrService.error(
        this.translateService.instant('general.something_went_wrong'),
      );
    }
  }

  @Action(RemoveShiftWithoutActivitiesFromUnassignedRegion)
  removeShiftWithoutActivitiesFromUnassignedRegion(
    ctx: StateContext<ShiftTimelineUnassignedRegionStateModel>,
    action: RemoveShiftWithoutActivitiesFromUnassignedRegion,
  ) {
    const state = ctx.getState();
    const shifts = {
      ...state.unassignedShiftsWithoutActivities,
    };
    delete shifts[action.payload.shiftId];

    ctx.patchState({
      unassignedShiftsWithoutActivities: shifts,
    });
  }

  @Action(BulkDeleteShiftActivitiesFromUnassignedRegion)
  bulkDeleteShiftActivitiesToUnassignedRegion(
    ctx: StateContext<ShiftTimelineUnassignedRegionStateModel>,
    action: BulkDeleteShiftActivitiesFromUnassignedRegion,
  ) {
    const state = ctx.getState();
    const shiftActivitiesRows = cloneDeep(state.shiftActivitiesRows);
    const activityRows = shiftActivitiesRows[action.shiftId];
    if (activityRows) {
      delete shiftActivitiesRows[action.shiftId];
    }
    ctx.setState(
      patch({
        shiftActivitiesRows: shiftActivitiesRows,
      }),
    );
  }

  @Action(BulkAddShiftActivitiesToUnassignedRegion)
  addShiftActivitiesToUnassignedRegion(
    ctx: StateContext<ShiftTimelineUnassignedRegionStateModel>,
    action: BulkAddShiftActivitiesToUnassignedRegion,
  ) {
    ctx.setState(
      patch({
        shiftActivitiesRows: patch({
          [action.shiftId]: action.activityRow,
        }),
      }),
    );
  }

  @Action(AddShiftWithoutActivitiesToCellViewUnassignedRegion)
  addShiftWithoutActivitiesToCellViewUnassignedRegion(
    ctx: StateContext<ShiftTimelineUnassignedRegionStateModel>,
    action: AddShiftWithoutActivitiesToCellViewUnassignedRegion,
  ) {
    const startDateTime =
      ShiftTimelineUnassignedRegionState.getUnassignedDateToShiftKey(
        determineShiftRenderDatetime(action.shift).startDatetime.date,
      );

    ctx.setState(
      patch({
        unassignedDateToShiftRecords: patch({
          [startDateTime]: patch({
            [action.shift.id]: action.shift,
          }),
        }),
      }),
    );
  }

  @Action(AddShiftWithoutActivitiesToUnassignedRegion)
  addShiftWithoutActivitiesToUnassignedRegion(
    ctx: StateContext<ShiftTimelineUnassignedRegionStateModel>,
    action: AddShiftWithoutActivitiesToUnassignedRegion,
  ) {
    ctx.setState(
      patch({
        unassignedShiftsWithoutActivities: patch({
          [action.shift.id]: action.shift,
        }),
      }),
    );
  }

  @Action(TimelineUpsertActivityToUnassignedRegion)
  timelineUpsertActivityToUnassignedRegion(
    ctx: StateContext<ShiftTimelineUnassignedRegionStateModel>,
    action: TimelineUpsertActivityToUnassignedRegion,
  ) {
    const state = ctx.getState();
    let activityRows = state.shiftActivitiesRows[action.shiftId];

    if (!activityRows) {
      activityRows = [];
    }
    const allActivities = activityRows.reduce((acc, row) => {
      return { ...acc, ...row };
    }, {});

    if (action.activity.id) {
      allActivities[action.activity.id] = action.activity;
    }

    const determinedActivities =
      this.activitiesStateLoaderService.getActivityRows(
        Object.values(allActivities) as (Activity & { id: string })[],
      );

    ctx.setState(
      patch({
        shiftActivitiesRows: patch({
          [action.shiftId]: determinedActivities,
        }),
      }),
    );
  }

  @Action(TimelineRemoveActivityFromUnassignedRegion)
  timelineRemoveActivityFromUnassignedRegion(
    ctx: StateContext<ShiftTimelineUnassignedRegionStateModel>,
    action: TimelineRemoveActivityFromUnassignedRegion,
  ) {
    const state = ctx.getState();
    let activityRows = state.shiftActivitiesRows[action.shiftId];

    if (!activityRows) {
      activityRows = [];
    }
    const allActivities = activityRows.reduce((acc, row) => {
      return { ...acc, ...row };
    }, {});

    delete allActivities[action.activityId];

    const determinedActivities =
      this.activitiesStateLoaderService.getActivityRows(
        Object.values(allActivities) as (Activity & { id: string })[],
      );

    ctx.setState(
      patch({
        shiftActivitiesRows: patch({
          [action.shiftId]: determinedActivities,
        }),
      }),
    );
  }

  private fetchUnassignedShiftsAndPrepareDataForStandardView(
    timeFrame: Interval,
  ) {
    this.unsubRequestForUnassignedShiftsSubject.next(null);
    return this.getShiftsRequest(timeFrame).pipe(
      takeUntil(this.unsubRequestForUnassignedShiftsSubject),
      map((response) => {
        const transformedShifts = response.reduce((record, shift) => {
          record[shift.id] = shift;
          return record;
        }, {} as Record<ShiftId, UnassignedShift>);

        return transformedShifts;
      }),
    );
  }

  fetchUnassignedShiftsAndPrepareDataForCellView(
    timeFrame: Interval,
  ): Observable<UnassignedDateToShiftRecords> {
    this.unsubRequestForUnassignedShiftsSubject.next(null);
    return this.getShiftsRequest(timeFrame).pipe(
      takeUntil(this.unsubRequestForUnassignedShiftsSubject),
      map((response) => {
        // TODO: create test for this algorithm
        if (response.length) {
          const transformedShifts = response.reduce((record, shift) => {
            const shiftStartDateKey =
              ShiftTimelineUnassignedRegionState.getUnassignedDateToShiftKey(
                getStartDatetime(shift).date,
              );

            record[shiftStartDateKey] = {
              ...record[shiftStartDateKey],
              [shift.id as string]: shift,
            };

            return record;
          }, {} as UnassignedDateToShiftRecords);

          return transformedShifts;
        } else {
          return {};
        }
      }),
    );
  }

  private fetchUnassignedShiftsCountPerDay(
    timeFrame: Interval,
  ): Observable<NumberOfShiftPerDayRecord> {
    this.unsubRequestForUnassignedShiftsSubject.next(null);

    return this.getUnassignedTimelinesShiftsCount(timeFrame).pipe(
      takeUntil(this.unsubRequestForUnassignedShiftsSubject),
      map((response) => {
        if (response.length) {
          return response.reduce((record, { date, numberOfShifts }) => {
            const startDateKey =
              ShiftTimelineUnassignedRegionState.getUnassignedDateToShiftKey(
                date,
              );

            record[startDateKey] = numberOfShifts;
            return record;
          }, {} as NumberOfShiftPerDayRecord);
        } else {
          return {};
        }
      }),
    );
  }

  private getUnassignedTimelinesShiftsCount(
    timeFrame: Interval,
  ): Observable<NumberOfShiftPerDay[]> {
    const correctedInterval = getTimeZoneCorrectedDateRange({
      start: timeFrame.start as Date,
      end: timeFrame.end as Date,
    });

    return from(
      this.shiftTimelineFilterStateService.generateShiftFilterOptionsForUnassignedRegion(
        correctedInterval.start,
        correctedInterval.end,
      ),
    ).pipe(
      switchMap((options) =>
        this.userTimelinesGateway.getUnassignedTimelinesShiftsCount(options),
      ),
    );
  }

  private getShiftsRequest(timeFrame: Interval) {
    const correctedInterval = getTimeZoneCorrectedDateRange({
      start: timeFrame.start as Date,
      end: timeFrame.end as Date,
    });

    return from(
      this.shiftTimelineFilterStateService.generateShiftFilterOptionsForUnassignedRegion(
        correctedInterval.start,
        correctedInterval.end,
      ),
    ).pipe(
      switchMap((options) =>
        this.userTimelinesGateway.getUnassignedTimelinesShiftsV3(options),
      ),
    );
  }

  @Action(AddShiftTemplateToSuggestionList)
  addShiftTemplateToSuggestionList(
    ctx: StateContext<ShiftTimelineUnassignedRegionStateModel>,
    action: AddShiftTemplateToSuggestionList,
  ) {
    const state = ctx.getState();
    const suggestedShiftTemplates = cloneDeep(state.suggestedShiftTemplates);
    if (
      action.shiftTemplate &&
      suggestedShiftTemplates.findIndex(
        (template) => template.id === action.shiftTemplate?.id,
      ) === -1
    ) {
      if (suggestedShiftTemplates.length === 3) {
        suggestedShiftTemplates.shift();
      }
      suggestedShiftTemplates.push(action.shiftTemplate);
    }
    ctx.patchState({
      suggestedShiftTemplates,
    });
  }

  @Action(AddActivityToUnassignedRegion)
  addActivityToUnassignedRegion(
    ctx: StateContext<ShiftTimelineUnassignedRegionStateModel>,
    action: AddActivityToUnassignedRegion,
  ) {
    const state = ctx.getState();
    const activities = cloneDeep(state.unassignedActivities);
    activities.push(action.activity);
    const updatablePartialState =
      this.timelineFetchUnassignedActivitiesActionService.getUpdatablePartialStateSync(
        activities,
      );
    ctx.patchState({
      ...updatablePartialState,
      unassignedActivities: activities,
    });
  }

  @Action(RemoveActivityFromUnassignedRegion)
  removeActivityFromUnassignedRegion(
    ctx: StateContext<ShiftTimelineUnassignedRegionStateModel>,
    action: RemoveActivityFromUnassignedRegion,
  ) {
    const state = ctx.getState();
    const activities = cloneDeep(state.unassignedActivities);
    const activityToRemoveIndex = activities.findIndex(
      (shift) => shift.id === action.activity.id,
    );
    activities.splice(activityToRemoveIndex, 1);

    const updatablePartialState =
      this.timelineFetchUnassignedActivitiesActionService.getUpdatablePartialStateSync(
        activities,
      );
    ctx.patchState({
      ...updatablePartialState,
      unassignedActivities: activities,
    });
  }

  @Action(FetchActivitiesForUnassignedShifts)
  fetchActivitiesForUnassignedShifts(
    ctx: StateContext<ShiftTimelineUnassignedRegionStateModel>,
    { shiftIds }: FetchActivitiesForUnassignedShifts,
  ) {
    this.activitiesStateLoaderService.loadActivitiesToUnassignedRegionState(
      ctx,
      shiftIds,
    );
  }

  static getShiftFromUnassignedDateRecords(
    unassignedDateToShiftRecords: UnassignedDateToShiftRecords,
    shiftId: string,
  ) {
    const dateRecords = Object.values(unassignedDateToShiftRecords);

    for (const shiftsById of dateRecords) {
      if (shiftsById[shiftId]) {
        return shiftsById[shiftId];
      }
    }
    return undefined;
  }
}
