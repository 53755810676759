import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { AbilityModule } from '@casl/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FeatureFlagsModule } from '@wilson/feature-flags';
import {
  RoleAction,
  RolePermissionSubject,
  Shift,
  ShiftState,
} from '@wilson/interfaces';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { AuthorizationModule } from '@wilson/authorization';
import { firstValueFrom } from 'rxjs';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ShiftsService } from '@wilson/shifts';
import { FeatureName } from '@wilson/interfaces';

@Component({
  selector: 'wilson-accept-shift-button',
  standalone: true,
  imports: [
    CommonModule,
    NzButtonModule,
    TranslateModule,
    FeatureFlagsModule,
    AbilityModule,
    AuthorizationModule,
  ],
  templateUrl: './accept-shift-button.component.html',
  styleUrl: './accept-shift-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AcceptShiftButtonComponent {
  public ShiftState = ShiftState;

  @Input() disabled = false;
  @Input({
    required: true,
  })
  shift!: Shift;
  @Output() updateSuccess = new EventEmitter<void>();
  FeatureName = FeatureName;
  RoleAction = RoleAction;
  RolePermissionSubject = RolePermissionSubject;

  constructor(
    private readonly shiftsService: ShiftsService,
    private readonly translate: TranslateService,
    private readonly message: NzMessageService,
  ) {}

  async updateShiftState() {
    try {
      await firstValueFrom(
        this.shiftsService.updateShiftsBulk([
          { id: this.shift.id, state: ShiftState.AcceptedTimes },
        ]),
      );

      this.updateSuccess.emit();
      this.message.success(this.translate.instant('popup.save.text'));
    } catch {
      this.message.error(
        this.translate.instant('general.something_went_wrong'),
      );
      throw Error;
    }
  }
}
