import { Injectable } from '@angular/core';
import { Selector, State } from '@ngxs/store';
import { eachDayOfInterval } from 'date-fns';
import {
  ShiftTimelineDataStateModel,
  ShiftTimelineFilterStateModel,
  ShiftTimelineInteractionsModel,
  ShiftTimelineSettingsModel,
  TimelineModalFilterStateModel,
} from './interfaces';
import { ShiftTimelineDataState } from './shift-timeline-data/shift-timeline-data.state';
import { ShiftTimelineSettingsState } from './shift-timeline-settings/shift-timeline-settings.state';
import { ShiftTimelineFiltersState } from './shift-timeline-filters/shift-timeline-filters.state';
import { ShiftTimelineInteractionsState } from './shift-timeline-interactions/shift-timeline-interactions.state';
import { ShiftTimelineUnassignedRegionState } from './shift-timeline-unassigned-region/shift-timeline-unassigned-region.state';
import { TimelineModalFilterState } from './timeline-modal-filters/timeline-modal-filters.state';
import { ShiftTimelineFilterUnassignedRegionState } from './shift-timeline-filter-unassigned-region/shift-timeline-filter-unassigned-region.state';

export interface ShiftTimelineStateModel {
  shiftTimelineData: ShiftTimelineDataStateModel;
  shiftTimelineFilter: ShiftTimelineFilterStateModel;
  shiftTimelineInteractions: ShiftTimelineInteractionsModel;
  timelineModalFilterState: TimelineModalFilterStateModel;
}
@State({
  name: 'shiftTimeline',
  children: [
    ShiftTimelineDataState,
    ShiftTimelineFiltersState,
    ShiftTimelineInteractionsState,
    ShiftTimelineUnassignedRegionState,
    ShiftTimelineFilterUnassignedRegionState,
    TimelineModalFilterState,
  ],
})
@Injectable()
export class ShiftTimelineState {
  @Selector([
    ShiftTimelineSettingsState.pixelWidthPerDay,
    ShiftTimelineDataState.timeframe,
  ])
  static frameLength(
    timeframeWidth: ShiftTimelineSettingsModel['pixelWidthPerDay'],
    timeframe: ShiftTimelineDataStateModel['timeframe'],
  ): number {
    return eachDayOfInterval(timeframe).length * timeframeWidth;
  }
}
