import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PdfGeneratedResponse, TimelineStayGateway } from '@wilson/api/gateway';
import {
  LocationAndDateTimeRange,
  Shift,
  Stay,
  StayDTO,
  StayStatus,
  StaySuggestionInfo,
  StayType,
  TimelineStayManagementDrawerFormFieldEnum,
  TimelineStayManagementDrawerFormValue,
  WithPreparedAttributes,
} from '@wilson/interfaces';
import { determineShiftRenderDatetime } from '@wilson/shift-timeline/services';
import { base64ToBlob, DateTimeFormat } from '@wilson/utils';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { FileSaverService } from 'ngx-filesaver';
import { firstValueFrom } from 'rxjs';
import { StayPDFNoteComponent } from '../stay-pdf-note/stay-pdf-note.component';
import { format } from 'date-fns';
import { Store } from '@ngxs/store';
import { AuthState } from '@wilson/auth/core';

export enum StayPDFType {
  Reimbursement = 'reimbursement',
  Cancellation = 'cancellation',
}
@Injectable({
  providedIn: 'root',
})
export class StaysDrawerHelperService {
  constructor(
    private readonly modal: NzModalService,
    private readonly translate: TranslateService,
    private readonly fileSaverService: FileSaverService,
    private readonly timelineStayGateway: TimelineStayGateway,
    private readonly nzMessageService: NzMessageService,
    private readonly store: Store,
  ) {}

  public preparePrefillEditStayObject(
    value: Stay | null,
  ): Partial<TimelineStayManagementDrawerFormValue | undefined> {
    if (value) {
      const staysPrefillObject: Partial<TimelineStayManagementDrawerFormValue> =
        {
          [TimelineStayManagementDrawerFormFieldEnum.LocationId]:
            value.locationId,
          [TimelineStayManagementDrawerFormFieldEnum.StayStatus]: value.status,
          [TimelineStayManagementDrawerFormFieldEnum.StayType]: value.type,
          [TimelineStayManagementDrawerFormFieldEnum.TimeFrame]: [
            new Date(value.startDatetime),
            new Date(value.endDatetime),
          ],
          [TimelineStayManagementDrawerFormFieldEnum.CheckInDateTime]: new Date(
            value.startDatetime,
          ),
          [TimelineStayManagementDrawerFormFieldEnum.CheckOutDateTime]:
            new Date(value.endDatetime),
          [TimelineStayManagementDrawerFormFieldEnum.PriceTotal]:
            value.priceTotal as number,
          [TimelineStayManagementDrawerFormFieldEnum.PaidNights]:
            value.numPaidNights as number,

          [TimelineStayManagementDrawerFormFieldEnum.AccomodationName]:
            value.accommodationName as string,
          [TimelineStayManagementDrawerFormFieldEnum.Address]:
            value.address as string,
          [TimelineStayManagementDrawerFormFieldEnum.PhoneNumber]:
            value.phoneNumber as string,
          [TimelineStayManagementDrawerFormFieldEnum.Note]:
            value.note as string,
          [TimelineStayManagementDrawerFormFieldEnum.UserId]: value.userId,
          [TimelineStayManagementDrawerFormFieldEnum.CancellationDate]:
            value.cancellationDate ? new Date(value.cancellationDate) : null,
          [TimelineStayManagementDrawerFormFieldEnum.Attachments]:
            value.attachments || [],
          [TimelineStayManagementDrawerFormFieldEnum.CostCenter]:
            value.costCenter as string,
          [TimelineStayManagementDrawerFormFieldEnum.Creditor]:
            value.creditor as string,
          [TimelineStayManagementDrawerFormFieldEnum.Email]:
            value.email as string,
          [TimelineStayManagementDrawerFormFieldEnum.InvoiceReference]:
            value.invoiceReference as string,
          [TimelineStayManagementDrawerFormFieldEnum.StandardRate]:
            value.standardRate as number,
        };
      return staysPrefillObject;
    } else {
      return undefined;
    }
  }

  public preparePrefillCreateStayObject(
    previousShift:
      | (Shift &
          WithPreparedAttributes & {
            id: string;
          })
      | null,
    nextTimelineCard: LocationAndDateTimeRange,
  ): Partial<TimelineStayManagementDrawerFormValue> | undefined {
    if (previousShift && nextTimelineCard) {
      const determinedPreviousShiftDetails =
        determineShiftRenderDatetime(previousShift);
      const previousLocationId = previousShift.reportedEndLocationId
        ? previousShift.reportedEndLocationId
        : previousShift.endLocationId;

      const staysPrefillObject: Partial<TimelineStayManagementDrawerFormValue> =
        {
          [TimelineStayManagementDrawerFormFieldEnum.IsOverNightStayRequired]:
            !!previousShift.ignoreStayNecessary,
          [TimelineStayManagementDrawerFormFieldEnum.LocationId]:
            previousLocationId,
          [TimelineStayManagementDrawerFormFieldEnum.CheckInDateTime]: new Date(
            determinedPreviousShiftDetails.endDatetime.date,
          ),
          [TimelineStayManagementDrawerFormFieldEnum.CheckOutDateTime]:
            new Date(nextTimelineCard.startDateTime),
          [TimelineStayManagementDrawerFormFieldEnum.TimeFrame]: [
            new Date(determinedPreviousShiftDetails.endDatetime.date),
            new Date(nextTimelineCard.startDateTime),
          ],
          [TimelineStayManagementDrawerFormFieldEnum.UserId]:
            previousShift.userId,
          [TimelineStayManagementDrawerFormFieldEnum.Attachments]: [],
        };

      return staysPrefillObject;
    } else {
      return undefined;
    }
  }

  public preparePrefillCreateStayFromBasicInfo(
    staySuggestionInfo: StaySuggestionInfo | null,
  ): Partial<TimelineStayManagementDrawerFormValue> | undefined {
    if (staySuggestionInfo) {
      const dateTime = new Date(staySuggestionInfo.date);
      const staysPrefillObject: Partial<TimelineStayManagementDrawerFormValue> =
        {
          [TimelineStayManagementDrawerFormFieldEnum.LocationId]:
            staySuggestionInfo.locationId,
          [TimelineStayManagementDrawerFormFieldEnum.CheckInDateTime]: dateTime,
          [TimelineStayManagementDrawerFormFieldEnum.CheckOutDateTime]:
            dateTime,
          [TimelineStayManagementDrawerFormFieldEnum.TimeFrame]: [
            dateTime,
            dateTime,
          ],
          [TimelineStayManagementDrawerFormFieldEnum.UserId]:
            staySuggestionInfo.userId,
          [TimelineStayManagementDrawerFormFieldEnum.CancellationDate]:
            staySuggestionInfo.cancellationDate
              ? new Date(staySuggestionInfo.cancellationDate)
              : null,
          [TimelineStayManagementDrawerFormFieldEnum.Attachments]: [],
        };

      return staysPrefillObject;
    } else {
      return undefined;
    }
  }

  public prepareFormFillValuesFromPreviousAccomodations(
    stay: Stay,
  ): Partial<TimelineStayManagementDrawerFormValue | undefined> {
    if (stay) {
      const staysPrefillObject: Partial<TimelineStayManagementDrawerFormValue> =
        {
          [TimelineStayManagementDrawerFormFieldEnum.AccomodationName]:
            stay.accommodationName,
          [TimelineStayManagementDrawerFormFieldEnum.Address]: stay.address,
          [TimelineStayManagementDrawerFormFieldEnum.PaidNights]:
            stay.numPaidNights,
          [TimelineStayManagementDrawerFormFieldEnum.PhoneNumber]:
            stay.phoneNumber,
          [TimelineStayManagementDrawerFormFieldEnum.PriceTotal]:
            stay.priceTotal,
          [TimelineStayManagementDrawerFormFieldEnum.CostCenter]:
            stay.costCenter,
          [TimelineStayManagementDrawerFormFieldEnum.Creditor]: stay.creditor,
          [TimelineStayManagementDrawerFormFieldEnum.Email]: stay.email,
          [TimelineStayManagementDrawerFormFieldEnum.InvoiceReference]:
            stay.invoiceReference,
          [TimelineStayManagementDrawerFormFieldEnum.StandardRate]:
            stay.standardRate,
        };

      return staysPrefillObject;
    } else {
      return undefined;
    }
  }

  public prepareStaysObject(
    formValue: Partial<TimelineStayManagementDrawerFormValue>,
    defaultValues?: Stay,
  ): StayDTO {
    const staysObject: StayDTO = {
      cancellationDate:
        formValue[
          TimelineStayManagementDrawerFormFieldEnum.CancellationDate
        ]?.toISOString() ||
        defaultValues?.cancellationDate ||
        null,
      userId:
        formValue[TimelineStayManagementDrawerFormFieldEnum.UserId] ||
        defaultValues?.userId ||
        '',
      locationId:
        formValue[TimelineStayManagementDrawerFormFieldEnum.LocationId] ||
        defaultValues?.locationId ||
        '',
      type:
        (formValue[
          TimelineStayManagementDrawerFormFieldEnum.StayType
        ] as StayType) || defaultValues?.type,
      startDatetime:
        new Date(
          formValue[
            TimelineStayManagementDrawerFormFieldEnum.CheckInDateTime
          ] as Date,
        ).toISOString() ||
        defaultValues?.startDatetime ||
        '',
      endDatetime:
        new Date(
          formValue[
            TimelineStayManagementDrawerFormFieldEnum.CheckOutDateTime
          ] as Date,
        ).toISOString() ||
        defaultValues?.endDatetime ||
        '',
      accommodationName:
        (formValue[
          TimelineStayManagementDrawerFormFieldEnum.AccomodationName
        ] as string) ||
        defaultValues?.accommodationName ||
        '',
      address:
        (formValue[
          TimelineStayManagementDrawerFormFieldEnum.Address
        ] as string) ||
        defaultValues?.address ||
        '',
      note:
        (formValue[TimelineStayManagementDrawerFormFieldEnum.Note] as string) ||
        defaultValues?.note ||
        '',
      numPaidNights:
        (formValue[
          TimelineStayManagementDrawerFormFieldEnum.PaidNights
        ] as number) || defaultValues?.numPaidNights,
      phoneNumber:
        (formValue[
          TimelineStayManagementDrawerFormFieldEnum.PhoneNumber
        ] as string) || defaultValues?.phoneNumber,
      priceTotal:
        (formValue[
          TimelineStayManagementDrawerFormFieldEnum.PriceTotal
        ] as number) || defaultValues?.priceTotal,
      status:
        (formValue[
          TimelineStayManagementDrawerFormFieldEnum.StayStatus
        ] as StayStatus) || defaultValues?.status,
      costCenter:
        (formValue[
          TimelineStayManagementDrawerFormFieldEnum.CostCenter
        ] as string) || defaultValues?.costCenter,
      creditor:
        (formValue[
          TimelineStayManagementDrawerFormFieldEnum.Creditor
        ] as string) || defaultValues?.creditor,
      email:
        (formValue[
          TimelineStayManagementDrawerFormFieldEnum.Email
        ] as string) || defaultValues?.email,
      invoiceReference:
        (formValue[
          TimelineStayManagementDrawerFormFieldEnum.InvoiceReference
        ] as string) || defaultValues?.invoiceReference,
      standardRate:
        (formValue[
          TimelineStayManagementDrawerFormFieldEnum.StandardRate
        ] as number) || defaultValues?.standardRate,
    };
    return staysObject;
  }

  public createHotelReimbursement(stay: Stay, type: StayPDFType) {
    const modalRef = this.modal.create({
      nzContent: StayPDFNoteComponent,
      nzTitle:
        type === StayPDFType.Reimbursement
          ? this.translate.instant(
              'page.shift_timeline.stay.hotel_reimbursement',
            )
          : this.translate.instant('page.stays.cancel_stay_and_notify_hotel'),
      nzOkText: this.translate.instant('general.accept'),
      nzCancelText: this.translate.instant('general.cancel'),
      nzOnOk: async (modalData) => {
        try {
          modalRef.updateConfig({ nzOkLoading: true, nzOkDisabled: true });
          const note = modalData.note || '';
          const pdf = await firstValueFrom(
            this.timelineStayGateway.createReimbursementPdf(stay.id, note),
          );
          this.savePdf(pdf);
          this.openMailBox(stay, type);
          modalRef.destroy();
        } catch {
          modalRef.updateConfig({ nzOkLoading: false, nzOkDisabled: false });
          this.nzMessageService.error(
            this.translate.instant('general.something_went_wrong'),
          );
        }
        return false;
      },
      nzCentered: true,
    });

    return modalRef.afterClose;
  }

  private openMailBox(stay: Stay, type: StayPDFType) {
    const email = stay.email || '';
    const user = this.store.selectSnapshot(AuthState.user);

    const subject =
      type === StayPDFType.Reimbursement
        ? this.translate.instant('page.stays.email.reimbursement.subject', {
            date: format(
              new Date(stay.startDatetime),
              DateTimeFormat.GermanDateFormat,
            ),
          })
        : this.translate.instant('page.stays.email.cancellation.subject', {
            date: format(
              new Date(stay.startDatetime),
              DateTimeFormat.GermanDateFormat,
            ),
          });
    const body =
      type === StayPDFType.Reimbursement
        ? this.translate.instant('page.stays.email.reimbursement.body', {
            firstName: user?.firstName || '',
            lastName: user?.lastName || '',
          })
        : this.translate.instant('page.stays.email.cancellation.body', {
            firstName: user?.firstName || '',
            lastName: user?.lastName || '',
          });

    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(
      subject,
    )}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
  }

  private savePdf(pdfData: PdfGeneratedResponse) {
    const pdfBlob = base64ToBlob(
      pdfData.response,
      pdfData.meta['content-type'],
    );

    this.fileSaverService.save(pdfBlob, pdfData.meta.name);
  }
}
