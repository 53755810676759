import { StateContext, StateOperator } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { Base } from '@wilson/base';
import {
  Absence,
  AccurateDateIntervalWithAbsence,
  AccurateDateIntervalWithShift,
  AccurateDateIntervalWithStay,
  AssignmentStack,
  DetermineAssignmentsOverlapWorkerWrapper,
  Shift,
  Stay,
  UserTimelines,
  UserTimelinesWithoutActivitiesPayload,
  WithPreparedAttributes,
} from '@wilson/interfaces';
import {
  consoleInfo,
  isDebugEnabled,
} from '@wilson/non-domain-specific/logger/logger';
import { determineAssignmentsOverlaps } from '@wilson/non-domain-specific/overlap-helpers';
import { DateTimeFormat, joinById } from '@wilson/utils';
import { eachDayOfInterval, format, startOfDay } from 'date-fns';
import { cloneDeep } from 'lodash';
import {
  AbsenceId,
  ShiftId,
  ShiftTimelineDataStateModel,
  UserId,
} from '../interfaces';
import { determineShiftRenderDatetime } from '@wilson/shift-timeline/services';

export function zipRecords<T extends Base>(
  newData: Record<string, T>,
): StateOperator<Record<string, T>> {
  return (state: Readonly<Record<string, T>>) => {
    return Object.entries(newData).reduce(
      (acc: Record<string, T>, [newDataKey, newData]) => {
        acc[newDataKey] = newData;
        return acc;
      },
      {
        ...state,
      },
    );
  };
}
export function zipNestedRecords<T extends Base>(
  newData: Record<string, Record<string, T>[]>,
): StateOperator<Record<string, Record<string, T>[]>> {
  return (state: Readonly<Record<string, Record<string, T>[]>>) => {
    return Object.entries(newData).reduce(
      (
        acc: Record<string, Record<string, T>[]>,
        [newDataKey, newDataValue],
      ) => {
        acc[newDataKey] = newDataValue;
        return acc;
      },
      {
        ...state,
      },
    );
  };
}

export function zipItem<T extends Base>(newData: T[]): StateOperator<T[]> {
  return (state: Readonly<T[]>) => {
    const stateClone = [...state];
    return joinById(stateClone, newData);
  };
}

export function clearLoadingUserIds(
  ctx: StateContext<ShiftTimelineDataStateModel>,
  userIds: string[],
) {
  const previousIds = ctx
    .getState()
    .usersWithUpdatingValidations.filter((userId) => !userIds.includes(userId));

  ctx.patchState({
    usersWithUpdatingShiftAssignments: previousIds,
    isLoadingUsers: false,
  });
}

export async function updateRowOverlapDataSyncOrAssignmentsStack({
  ctx,
  userIds,
  visibleDateRange,
  isCellView,
}: {
  ctx: StateContext<ShiftTimelineDataStateModel>;
  userIds: string[];
  visibleDateRange: {
    start: Date | null;
    end: Date | null;
  };
  isCellView: boolean;
}) {
  const { usersDictionary, dateToUserIdAssignmentIdRecord } = ctx.getState();

  // We only calculate overlap based on current range
  const filteredUsersTimelineData = getDataInRange({
    usersDictionary,
    userIds,
    visibleDateRange,
    dateToUserIdAssignmentIdRecord,
  });

  if (isCellView) {
    determineAssignmentStack(filteredUsersTimelineData, ctx.setState);
  } else {
    const userRowsData: {
      userId: string;
      rows: {
        shifts: AccurateDateIntervalWithShift[];
        absences: AccurateDateIntervalWithAbsence[];
        stays: AccurateDateIntervalWithStay[];
      }[];
      resultTimestamp: number;
    }[] = filteredUsersTimelineData.map(
      ({
        id,
        shiftsWithoutActivitiesDictionary,
        absencesDictionary,
        staysDictionary,
      }) => {
        const absences = Object.values(absencesDictionary);
        const shifts = Object.values(shiftsWithoutActivitiesDictionary);
        const stays = Object.values(staysDictionary);
        return {
          userId: id as string,
          rows: determineAssignmentsOverlaps({
            shifts,
            absences,
            stays,
            isDebugEnabled: isDebugEnabled(),
          }),
          resultTimestamp: new Date().getTime(),
        };
      },
    );

    updateRowOverlapDataInState(userRowsData, usersDictionary, ctx.setState);
  }
}

export async function updateRowOverlapOrAssignmentStacks({
  determineAssignmentsOverlapWorkerWrapper,
  ctx,
  userIds,
  visibleDateRange,
  isCellView,
}: {
  determineAssignmentsOverlapWorkerWrapper: DetermineAssignmentsOverlapWorkerWrapper;
  ctx: StateContext<ShiftTimelineDataStateModel>;
  userIds: string[];
  visibleDateRange: {
    start: Date | null;
    end: Date | null;
  };
  isCellView: boolean;
}) {
  const { usersDictionary, dateToUserIdAssignmentIdRecord } = ctx.getState();

  // We only calculate overlap based on current range
  const filteredUsersTimelineData = getDataInRange({
    usersDictionary,
    userIds,
    visibleDateRange,
    dateToUserIdAssignmentIdRecord,
  });
  if (isCellView) {
    determineAssignmentStack(filteredUsersTimelineData, ctx.setState);
  } else {
    const userRowsData: {
      userId: string;
      rows: {
        shifts: AccurateDateIntervalWithShift[];
        absences: AccurateDateIntervalWithAbsence[];
        stays: AccurateDateIntervalWithStay[];
      }[];
      resultTimestamp: number;
    }[] = await determineAssignmentsOverlapWorkerWrapper.execute(
      filteredUsersTimelineData,
    );
    updateRowOverlapDataInState(userRowsData, usersDictionary, ctx.setState);
  }
}

function determineAssignmentStack(
  filteredUsersTimelineData: UserTimelines[],
  setState: StateContext<ShiftTimelineDataStateModel>['setState'],
): void {
  const userTimelineChangeInstructions = filteredUsersTimelineData.reduce(
    (
      instructions: Record<string, StateOperator<UserTimelines>>,
      userTimeline,
    ) => {
      const usersAssignmentStacks = calculateUserAssignmentStack(userTimeline);

      instructions[userTimeline.id as string] = patch({
        assignmentStacks: usersAssignmentStacks,
      });
      return instructions;
    },
    {},
  );

  setState(
    patch({
      usersDictionary: patch(userTimelineChangeInstructions),
    }),
  );
}

export function calculateUserAssignmentStack(
  userTimeline: UserTimelines,
): AssignmentStack[] {
  const userAssignmentStacks: AssignmentStack[] = [];
  const dateMap = new Map<string, AssignmentStack>();

  Object.values(userTimeline.absencesDictionary).forEach((absence) => {
    const absenceStartDateKey = startOfDay(new Date(absence.absentFrom));

    const absenceEndDateKey = startOfDay(new Date(absence.absentTo));

    eachDayOfInterval({
      start: absenceStartDateKey,
      end: absenceEndDateKey,
    }).forEach((dateInInterval) => {
      const dateInIntervalString = dateInInterval.toISOString();
      let assignment = dateMap.get(dateInIntervalString);

      if (assignment) {
        assignment.absences.push(absence);
      } else {
        assignment = {
          date: dateInInterval,
          absences: [absence],
          shifts: [],
        };
        dateMap.set(dateInIntervalString, assignment);
        userAssignmentStacks.push(assignment);
      }
    });
  });

  Object.values(userTimeline.shiftsWithoutActivitiesDictionary).forEach(
    (shift) => {
      const shiftStartDateKey = startOfDay(
        new Date(determineShiftRenderDatetime(shift).startDatetime.date),
      ).toISOString();

      let assignment = dateMap.get(shiftStartDateKey);

      if (assignment) {
        assignment.shifts.push(shift);
      } else {
        assignment = {
          date: new Date(shiftStartDateKey),
          absences: [],
          shifts: [shift],
        };
        dateMap.set(shiftStartDateKey, assignment);
        userAssignmentStacks.push(assignment);
      }
    },
  );

  return userAssignmentStacks;
}

function updateRowOverlapDataInState(
  userRowsData: {
    userId: string;
    rows: {
      shifts: AccurateDateIntervalWithShift[];
      absences: AccurateDateIntervalWithAbsence[];
      stays: AccurateDateIntervalWithStay[];
    }[];
    resultTimestamp: number;
  }[],
  usersDictionary: ShiftTimelineDataStateModel['usersDictionary'],
  setState: StateContext<ShiftTimelineDataStateModel>['setState'],
) {
  const patchInstruction = userRowsData.reduce(
    (instructions: Record<string, StateOperator<UserTimelines>>, userRows) => {
      const userFromState = usersDictionary[userRows.userId];
      if (userFromState.determinedRowDataTimestamp < userRows.resultTimestamp) {
        instructions[userRows.userId] = patch({
          determinedRowDataV2: userRows.rows,
          determinedRowDataTimestamp: userRows.resultTimestamp,
        });
      }

      return instructions;
    },
    {},
  );

  setState(
    patch({
      usersDictionary: patch(patchInstruction),
    }),
  );
}

export function getDataInRange({
  dateToUserIdAssignmentIdRecord,
  usersDictionary,
  userIds,
  visibleDateRange,
}: {
  dateToUserIdAssignmentIdRecord: ShiftTimelineDataStateModel['dateToUserIdAssignmentIdRecord'];
  usersDictionary: Record<string, UserTimelines>;
  userIds: string[];
  visibleDateRange: {
    start: Date | null;
    end: Date | null;
  };
}): UserTimelines[] {
  const dataInterval = eachDayOfInterval({
    start: new Date(visibleDateRange.start as Date),
    end: new Date(visibleDateRange.end as Date),
  });

  const filteredUsersTimelineData = userIds.map((userId) => {
    const filteredUser: UserTimelines = cloneDeep(usersDictionary[userId]);
    filteredUser.absencesDictionary = {};
    filteredUser.shiftsWithoutActivitiesDictionary = {};
    filteredUser.staysDictionary = {};

    const {
      shiftsWithoutActivitiesDictionary,
      absencesDictionary,
      staysDictionary,
    } = usersDictionary[userId];
    dataInterval.forEach((date) => {
      const dateKey = getDateKey(date);
      const assignmentsInViewForUser =
        dateToUserIdAssignmentIdRecord[dateKey]?.[userId];

      if (assignmentsInViewForUser) {
        Object.keys(assignmentsInViewForUser).forEach((assignmentId) => {
          if (assignmentId in shiftsWithoutActivitiesDictionary) {
            filteredUser.shiftsWithoutActivitiesDictionary[assignmentId] =
              cloneDeep(shiftsWithoutActivitiesDictionary[assignmentId]);
          } else if (assignmentId in absencesDictionary) {
            filteredUser.absencesDictionary[assignmentId] = cloneDeep(
              absencesDictionary[assignmentId],
            );
          } else if (assignmentId in staysDictionary) {
            filteredUser.staysDictionary[assignmentId] = cloneDeep(
              staysDictionary[assignmentId],
            );
          }
        });
      }
    });

    return filteredUser;
  });

  return filteredUsersTimelineData;
}

/**
 * Update in place
 */
export function updateInPlaceDateToShiftIdRecord({
  shift,
  dateToAssignmentIdsRecord,
  cleanUpRange,
}: {
  shift: Shift &
    WithPreparedAttributes & {
      id: string;
    };
  dateToAssignmentIdsRecord: ShiftTimelineDataStateModel['dateToUserIdAssignmentIdRecord'];
  cleanUpRange: {
    start: Date;
    end: Date;
  };
}) {
  removeInPlaceShiftIdInRange({
    assignmentId: shift.id,
    dateToAssignmentIdsRecord,
    cleanUpRange,
  });

  try {
    eachDayOfInterval({
      start: new Date(shift.startDatetime),
      end: new Date(shift.endDatetime),
    }).forEach((dateInInterval) => {
      const dateKey = getDateKey(dateInInterval);
      dateToAssignmentIdsRecord[dateKey] = {
        ...dateToAssignmentIdsRecord[dateKey],
        [shift.userId as string]: {
          ...dateToAssignmentIdsRecord[dateKey]?.[shift.userId as string],
          [shift.id]: undefined,
        },
      };
    });
  } catch (e) {
    console.error(e);
    consoleInfo(JSON.stringify(shift));
  }
}

/**
 * Update in place
 */
export function updateInPlaceDateToAbsenceIdRecord({
  absence,
  dateToAssignmentIdsRecord,
  cleanUpRange,
}: {
  absence: Absence & { id: string };
  dateToAssignmentIdsRecord: ShiftTimelineDataStateModel['dateToUserIdAssignmentIdRecord'];
  cleanUpRange: {
    start: Date;
    end: Date;
  };
}) {
  removeInPlaceShiftIdInRange({
    assignmentId: absence.id,
    dateToAssignmentIdsRecord,
    cleanUpRange,
  });
  try {
    eachDayOfInterval({
      start: new Date(absence.absentFrom),
      end: new Date(absence.absentTo),
    }).forEach((dateInInterval) => {
      const dateKey = getDateKey(dateInInterval);
      dateToAssignmentIdsRecord[dateKey] = {
        ...dateToAssignmentIdsRecord[dateKey],
        [absence.userId]: {
          ...dateToAssignmentIdsRecord[dateKey]?.[absence.userId],
          [absence.id]: undefined,
        },
      };
    });
  } catch (e) {
    console.error(e);
    consoleInfo(JSON.stringify(absence));
  }
}

/**
 * Update in place
 */
export function updateInPlaceDateToStayIdRecord({
  stay,
  dateToAssignmentIdsRecord,
  cleanUpRange,
}: {
  stay: Stay;
  dateToAssignmentIdsRecord: ShiftTimelineDataStateModel['dateToUserIdAssignmentIdRecord'];
  cleanUpRange: {
    start: Date;
    end: Date;
  };
}) {
  removeInPlaceShiftIdInRange({
    assignmentId: stay.id,
    dateToAssignmentIdsRecord,
    cleanUpRange,
  });
  try {
    eachDayOfInterval({
      start: new Date(stay.startDatetime),
      end: new Date(stay.endDatetime),
    }).forEach((dateInInterval) => {
      const dateKey = getDateKey(dateInInterval);
      dateToAssignmentIdsRecord[dateKey] = {
        ...dateToAssignmentIdsRecord[dateKey],
        [stay.userId]: {
          ...dateToAssignmentIdsRecord[dateKey]?.[stay.userId],
          [stay.id]: undefined,
        },
      };
    });
  } catch (e) {
    console.error(e);
    consoleInfo(JSON.stringify(stay));
  }
}

function removeInPlaceShiftIdInRange({
  assignmentId,
  dateToAssignmentIdsRecord,
  cleanUpRange,
}: {
  assignmentId: string;
  dateToAssignmentIdsRecord: ShiftTimelineDataStateModel['dateToUserIdAssignmentIdRecord'];
  cleanUpRange: {
    start: Date;
    end: Date;
  };
}) {
  try {
    eachDayOfInterval(cleanUpRange).forEach((dateInInterval) => {
      const dateKey = getDateKey(dateInInterval);
      if (dateKey in dateToAssignmentIdsRecord) {
        Object.entries(dateToAssignmentIdsRecord[dateKey]).forEach(
          ([userId, assignmentRecord]) => {
            if (assignmentId in assignmentRecord) {
              delete dateToAssignmentIdsRecord[dateKey][userId][assignmentId];
            }
          },
        );
      }
    });
  } catch (e) {
    console.error(e);
    consoleInfo(JSON.stringify(cleanUpRange));
  }
}

export function removeInPlaceFromUserInDateToAssignmentIdRecord(
  assignmentId: string,
  userId: string,
  dateToAssignmentIdsMap: ShiftTimelineDataStateModel['dateToUserIdAssignmentIdRecord'],
) {
  Object.keys(dateToAssignmentIdsMap).forEach((dateKey) => {
    if (userId in dateToAssignmentIdsMap[dateKey]) {
      delete dateToAssignmentIdsMap[dateKey][userId][assignmentId];
    }
  });
}

function getDateKey(date: Date) {
  return format(date, DateTimeFormat.DatabaseDateFormat);
}

export function reduceIncludingUnmatchingElements(
  users: UserTimelinesWithoutActivitiesPayload[],
  dateToAssignmentIdsRecord: Record<
    string,
    Record<UserId, Record<ShiftId | AbsenceId, undefined>>
  >,
  correctedInterval: { start: Date; end: Date },
): Record<string, StateOperator<UserTimelines>> {
  return users.reduce(
    (
      instructions: Record<string, StateOperator<UserTimelines>>,
      userTimeLineData,
    ) => {
      const newShiftsWithoutActivitiesRecord: Record<
        ShiftId,
        Shift &
          WithPreparedAttributes & {
            id: string;
          }
      > = {};
      userTimeLineData.shifts.forEach((shift) => {
        newShiftsWithoutActivitiesRecord[shift.id] = shift;
        updateInPlaceDateToShiftIdRecord({
          shift,
          dateToAssignmentIdsRecord,
          cleanUpRange: {
            start: correctedInterval.start,
            end: correctedInterval.end,
          },
        });
      });

      const newAbsences: Record<
        AbsenceId,
        Absence & { id: string; isHighlighted: boolean }
      > = {};
      userTimeLineData.absences.forEach((absence) => {
        newAbsences[absence.id] = absence;
        updateInPlaceDateToAbsenceIdRecord({
          absence,
          dateToAssignmentIdsRecord,
          cleanUpRange: {
            start: correctedInterval.start,
            end: correctedInterval.end,
          },
        });
      });

      instructions[userTimeLineData.id as string] = patch({
        shiftsWithoutActivitiesDictionary: zipRecords(
          newShiftsWithoutActivitiesRecord,
        ),
        absencesDictionary: zipRecords(newAbsences),
      });

      return instructions;
    },
    {},
  );
}
