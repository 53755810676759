import { Injectable } from '@angular/core';
import {
  Action,
  createSelector,
  Selector,
  State,
  StateContext,
} from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { UserTimelinesGateway } from '@wilson/api/gateway';
import {
  ActivitiesAssignment,
  Activity,
  ResolvedShiftValidation,
  RoleIDsMap,
  Shift,
  ShiftAssignment,
  UserTimelinesModal,
} from '@wilson/interfaces';
import {
  GanttScrollLeftPixelService,
  ShiftOverlappingService,
} from '@wilson/shift-timeline/services';
import { ShiftAssignmentService, ShiftsService } from '@wilson/shifts';
import { addDays, eachDayOfInterval, endOfDay, startOfDay } from 'date-fns';
import { catchError, finalize, first, of, Subject, takeUntil, tap } from 'rxjs';
import { ShiftTimelineFilterStateService } from '../shift-timeline-filters/shift-timeline-filter-state.service';
import {
  ResetShiftAssignmentModalState,
  SetActivitiesAssignmentWindowWidth,
  SetActivitiesToAssign,
  SetShiftToAssign,
  SetWindowWidth,
  TimelineFetchActivitiesAssignment,
  TimelineFetchShiftAssignments,
  TimelineModalFetchUsers,
  TimelineModalFetchUserValidations,
  TimelineModalSetDaysRange,
  TimelineModalSetTimeframeWidth,
  ToggleFilterLoading,
} from './shift-assignment-modal.actions';

export enum MODAL_VIEW {
  SHIFT = 'SHIFT',
  ACTIVITY = 'ACTIVITY',
}

const defaultTimeFrame = {
  start: startOfDay(new Date()),
  end: endOfDay(addDays(new Date(), 6)),
};

const timeframeWidthByModalView = new Map([
  [MODAL_VIEW.SHIFT, 480],
  [MODAL_VIEW.ACTIVITY, 1920],
]);

export interface ShiftAssignmentModalStateModel {
  timeframe: Interval;
  daysRange: Date[];
  timeframeWidth: number;
  resourcesColumnWidthPx: number;
  scrollToPx: number;
  windowWidth: number;
  shiftToAssign: (Shift & { activities: Activity[] }) | null;
  activitiesToAssign: Activity[];
  showLoading: boolean;
  isLoadingUsers: boolean;
  usersDictionary: Record<string, UserTimelinesModal>;
  shiftAssignment: ShiftAssignment[] | ActivitiesAssignment[];
  hasFetchedShiftAssignmentSuccessful: boolean;
  isLoadingValidations: boolean;
  isLoadingValidationsError: boolean;
  isLoadingValidationsV1Error: boolean;
  usersValidations: ResolvedShiftValidation[];
}

export const defaults: ShiftAssignmentModalStateModel = {
  timeframe: defaultTimeFrame,
  daysRange: eachDayOfInterval(defaultTimeFrame),
  timeframeWidth: timeframeWidthByModalView.get(MODAL_VIEW.SHIFT) as number,
  resourcesColumnWidthPx: 440,
  scrollToPx: 0,
  windowWidth: 0,
  shiftToAssign: null,
  activitiesToAssign: [],
  showLoading: false,
  isLoadingUsers: false,
  usersDictionary: {},
  shiftAssignment: [],
  hasFetchedShiftAssignmentSuccessful: false,
  isLoadingValidations: false,
  isLoadingValidationsError: false,
  isLoadingValidationsV1Error: false,
  usersValidations: [],
};

@State<ShiftAssignmentModalStateModel>({
  name: 'shiftAssignmentModal',
  defaults,
})
@Injectable()
export class ShiftAssignmentModalState {
  private unSubFetchUserRequestSubject = new Subject();
  private unSubFetchUserValidationRequestSubject = new Subject();

  constructor(
    private ganttScrollLeftPixelService: GanttScrollLeftPixelService,
    private userTimelineGateway: UserTimelinesGateway,
    private shiftAssignmentService: ShiftAssignmentService,
    private shiftsService: ShiftsService,
    private shiftTimelineFilterStateService: ShiftTimelineFilterStateService,
  ) {}

  @Selector()
  static resourcesColumnWidthPx(state: ShiftAssignmentModalStateModel) {
    return state.resourcesColumnWidthPx;
  }

  @Selector()
  static timeframe(state: ShiftAssignmentModalStateModel) {
    return state.timeframe;
  }

  @Selector()
  static daysRange(state: ShiftAssignmentModalStateModel) {
    return state.daysRange;
  }

  @Selector()
  static frameLength(state: ShiftAssignmentModalStateModel) {
    return state.daysRange.length * state.timeframeWidth;
  }

  @Selector()
  static shiftToAssign(state: ShiftAssignmentModalStateModel) {
    return state.shiftToAssign;
  }

  @Selector()
  static activitiesToAssign(state: ShiftAssignmentModalStateModel) {
    return state.activitiesToAssign;
  }

  @Selector()
  static scrollToPx(state: ShiftAssignmentModalStateModel) {
    return state.scrollToPx;
  }

  @Selector()
  static timelineModalUsers(
    state: ShiftAssignmentModalStateModel,
  ): UserTimelinesModal[] {
    return Object.values(state.usersDictionary);
  }

  @Selector()
  static showFilterLoading(state: ShiftAssignmentModalStateModel): boolean {
    return state.showLoading;
  }

  @Selector()
  static isFetchedShiftAssignmentSuccessful(
    state: ShiftAssignmentModalStateModel,
  ): boolean {
    return state.hasFetchedShiftAssignmentSuccessful;
  }

  @Selector()
  static isLoadingValidationsError(
    state: ShiftAssignmentModalStateModel,
  ): boolean {
    return state.isLoadingValidationsError;
  }

  @Selector()
  static isLoadingValidationsV1Error(
    state: ShiftAssignmentModalStateModel,
  ): boolean {
    return state.isLoadingValidationsV1Error;
  }

  @Selector()
  static usersValidations(
    state: ShiftAssignmentModalStateModel,
  ): ResolvedShiftValidation[] {
    return state.usersValidations;
  }

  @Selector()
  static shiftAssignment(
    state: ShiftAssignmentModalStateModel,
  ): ShiftAssignment[] | ActivitiesAssignment[] {
    return state.shiftAssignment;
  }

  static userShiftAssignment(userId: string | null) {
    return createSelector(
      [ShiftAssignmentModalState.shiftAssignment],
      (shiftAssignment: ShiftAssignment[] | ActivitiesAssignment[]) => {
        return shiftAssignment.find(
          (timelineShiftAssignment) =>
            timelineShiftAssignment.user.id === userId,
        );
      },
    );
  }

  @Action(ResetShiftAssignmentModalState)
  resetShiftAssignmentState(ctx: StateContext<ShiftAssignmentModalStateModel>) {
    ctx.patchState(defaults);
  }

  @Action(SetWindowWidth)
  setWindowWith(
    { patchState, getState }: StateContext<ShiftAssignmentModalStateModel>,
    { payload }: SetWindowWidth,
  ) {
    const state = getState();
    const updatePayload: Pick<
      ShiftAssignmentModalStateModel,
      'scrollToPx' | 'windowWidth'
    > = {
      windowWidth: payload.width,
      scrollToPx: state.scrollToPx,
    };

    if (state.shiftToAssign) {
      updatePayload.scrollToPx = this.updateScrollToPx(
        state.shiftToAssign.activities[0].startDatetime,
        state.shiftToAssign.activities[
          state.shiftToAssign.activities.length - 1
        ].endDatetime,
        payload.width,
        state.daysRange.length * state.timeframeWidth,
        state.daysRange,
        state.resourcesColumnWidthPx,
      );
    }

    patchState(updatePayload);
  }

  @Action(SetActivitiesAssignmentWindowWidth)
  setActivitiesAssignmentWindowWidth(
    { patchState, getState }: StateContext<ShiftAssignmentModalStateModel>,
    { payload }: SetActivitiesAssignmentWindowWidth,
  ) {
    const state = getState();
    const updatePayload: Pick<
      ShiftAssignmentModalStateModel,
      'scrollToPx' | 'windowWidth'
    > = {
      windowWidth: payload.width,
      scrollToPx: state.scrollToPx,
    };

    const shiftAssignment = state.shiftAssignment;

    const firstShiftActivities = shiftAssignment[0]?.shift.activities;
    if (firstShiftActivities && firstShiftActivities.length) {
      updatePayload.scrollToPx = this.updateScrollToPx(
        firstShiftActivities[0].startDatetime,
        firstShiftActivities[firstShiftActivities.length - 1].endDatetime,
        payload.width,
        state.daysRange.length * state.timeframeWidth,
        state.daysRange,
        state.resourcesColumnWidthPx,
      );
    }

    patchState(updatePayload);
  }

  @Action(SetShiftToAssign)
  setShiftToAssign(
    { patchState, getState }: StateContext<ShiftAssignmentModalStateModel>,
    { payload }: SetShiftToAssign,
  ) {
    const state = getState();
    const updatePayload: Pick<
      ShiftAssignmentModalStateModel,
      'shiftToAssign' | 'scrollToPx'
    > = {
      shiftToAssign: payload.shift,
      scrollToPx: state.scrollToPx,
    };
    updatePayload.scrollToPx = this.updateScrollToPx(
      payload.shift.activities[0].startDatetime,
      payload.shift.activities[payload.shift.activities.length - 1].endDatetime,
      state.windowWidth,
      state.daysRange.length * state.timeframeWidth,
      state.daysRange,
      state.resourcesColumnWidthPx,
    );

    patchState(updatePayload);
  }

  @Action(ToggleFilterLoading)
  ToggleFilterLoading(
    ctx: StateContext<ShiftAssignmentModalStateModel>,
    action: ToggleFilterLoading,
  ) {
    ctx.setState(
      patch<ShiftAssignmentModalStateModel>({
        showLoading: action.isLoading,
      }),
    );
  }

  @Action(TimelineModalSetDaysRange)
  timelineModalSetDaysRange(
    ctx: StateContext<ShiftAssignmentModalStateModel>,
    action: TimelineModalSetDaysRange,
  ) {
    ctx.setState(
      patch<ShiftAssignmentModalStateModel>({
        timeframe: {
          start: action.dates[0],
          end: action.dates[action.dates.length - 1],
        },
        daysRange: action.dates,
      }),
    );
  }

  @Action(TimelineModalSetTimeframeWidth)
  TimelineModalSetTimeframeWidth(
    ctx: StateContext<ShiftAssignmentModalStateModel>,
    action: TimelineModalSetTimeframeWidth,
  ) {
    ctx.setState(
      patch<ShiftAssignmentModalStateModel>({
        timeframeWidth: timeframeWidthByModalView.get(action.value),
      }),
    );
  }

  @Action(TimelineModalFetchUsers)
  async timelineModalFetchUsers(
    ctx: StateContext<ShiftAssignmentModalStateModel>,
    action: TimelineModalFetchUsers,
  ) {
    this.unSubFetchUserRequestSubject.next(null);
    if (action.timeframe.start && action.timeframe.end) {
      ctx.patchState({
        isLoadingUsers: true,
      });

      const options =
        await this.shiftTimelineFilterStateService.generateFilterOptionsForAssignedRegion(
          action.timeframe.start as Date,
          action.timeframe.end as Date,
          null,
        );

      return this.userTimelineGateway
        .getUserTimelinesWithActivities(options)
        .pipe(
          takeUntil(this.unSubFetchUserRequestSubject),
          tap((users) => {
            users = users.filter((user) => {
              if (action.assignmentTimeRange) {
                return (
                  user.userRoles.findIndex(
                    (role) => role.roleId === RoleIDsMap.OperationalStaff,
                  ) !== -1 &&
                  !ShiftOverlappingService.isShiftToAssignOverlappingWithNonRelevantAbsences(
                    user,
                    {
                      start: action.assignmentTimeRange.start,
                      end: action.assignmentTimeRange.end,
                    },
                  )
                );
              } else {
                return (
                  user.userRoles.findIndex(
                    (role) => role.roleId === RoleIDsMap.OperationalStaff,
                  ) !== -1
                );
              }
            });
            ctx.patchState({
              usersDictionary: Object.fromEntries(
                users.map((user) => [user.id, user]),
              ),
              isLoadingUsers: false,
            });
          }),
          catchError(() =>
            of({
              isRequestError: true,
              data: [],
            }),
          ),
        );
    } else {
      return of({
        isRequestError: true,
        data: [],
      });
    }
  }

  @Action(TimelineFetchShiftAssignments)
  timelineFetchShiftAssignments(
    ctx: StateContext<ShiftAssignmentModalStateModel>,
    action: TimelineFetchShiftAssignments,
  ) {
    ctx.patchState({
      hasFetchedShiftAssignmentSuccessful: false,
    });

    return this.shiftAssignmentService
      .getShiftAssignments(action.shiftToAssignId, true)
      .pipe(
        tap((shiftAssignment) => {
          ctx.patchState({
            shiftAssignment: shiftAssignment,
            hasFetchedShiftAssignmentSuccessful: true,
          });
        }),
        finalize(() => {
          ctx.patchState({
            shiftAssignment: [],
            hasFetchedShiftAssignmentSuccessful: false,
          });
        }),
      );
  }

  @Action(TimelineFetchActivitiesAssignment)
  timelineFetchActivitiesAssignment(
    ctx: StateContext<ShiftAssignmentModalStateModel>,
    action: TimelineFetchActivitiesAssignment,
  ) {
    ctx.patchState({
      hasFetchedShiftAssignmentSuccessful: false,
    });

    return this.shiftAssignmentService
      .getActivitiesAssignments(action.selectedActivityIds)
      .pipe(
        tap((activitiesAssignment) => {
          const state = ctx.getState();
          const filteredUsers = Object.values(state.usersDictionary).filter(
            (user) =>
              activitiesAssignment.find(
                (assignment) => assignment.user.id === user.id,
              ),
          );
          ctx.patchState({
            usersDictionary: Object.fromEntries(
              filteredUsers.map((user) => [user.id, user]),
            ),
            shiftAssignment: activitiesAssignment,
            hasFetchedShiftAssignmentSuccessful: true,
          });
        }),
      );
  }

  @Action(TimelineModalFetchUserValidations)
  async fetchUserValidations(
    ctx: StateContext<ShiftAssignmentModalStateModel>,
    action: TimelineModalFetchUserValidations,
  ) {
    this.unSubFetchUserValidationRequestSubject.next(null);
    const state = ctx.getState();
    ctx.patchState({
      isLoadingValidations: true,
      isLoadingValidationsError: false,
    });

    const timelineUserDataWithShifts = Object.values(
      state.usersDictionary,
    ).filter((user) => Object.values(user.shifts).length > 0);

    const userIdsWithShifts = timelineUserDataWithShifts.map((user) => user.id);

    return this.shiftsService
      .getInDateRangeWithValidations(
        action.timeframe.start as Date,
        action.timeframe.end as Date,
        { userId: userIdsWithShifts },
      )
      .pipe(
        first(),
        takeUntil(this.unSubFetchUserValidationRequestSubject),
        tap((validationResponse) => {
          const isLoadingValidationsV2Error =
            validationResponse.v2ValidationFlagEnabled
              ? validationResponse.v2RequestError
              : false;

          const isLoadingValidationsV1Error = validationResponse.v1RequestError;
          ctx.patchState({
            isLoadingValidationsV1Error,
            isLoadingValidationsError:
              isLoadingValidationsV1Error || isLoadingValidationsV2Error,
            isLoadingValidations: false,
            usersValidations: validationResponse.data,
          });
        }),
      );
  }

  @Action(SetActivitiesToAssign)
  setActivitiesToAssign(
    { patchState }: StateContext<ShiftAssignmentModalStateModel>,
    { payload }: SetActivitiesToAssign,
  ) {
    const updatePayload: Pick<
      ShiftAssignmentModalStateModel,
      'activitiesToAssign'
    > = {
      activitiesToAssign: payload,
    };
    patchState(updatePayload);
  }

  private updateScrollToPx(
    startDatetime: string,
    endDatetime: string,
    windowWidth: number,
    frameLength: number,
    daysRange: Date[],
    resourcesColumnWidthPx: number,
  ): number {
    return this.ganttScrollLeftPixelService.calculate({
      frameLength: frameLength,
      days: daysRange,
      startTime: startDatetime,
      endTime: endDatetime,
      windowWidth,
      resourcesColumnWidthPx: resourcesColumnWidthPx,
    });
  }
}
