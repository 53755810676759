<button
  nz-button
  class="w-100 fw-bold mb-2"
  (click)="updateShiftState()"
  [disabled]="disabled"
  *ngIf="
    (hasCompanyAdminRole$ | async) &&
    (shift?.state === ShiftState.AcceptedTimes ||
      shift?.state === ShiftState.SubmittedToPayrollProvider)
  "
>
  <fa-icon class="mx-2" [icon]="faUndo"></fa-icon>
  {{ 'page.shift_timeline.drawer.shift_form.revert_shift' | translate }}
</button>
