import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { UserFiltersDTO } from '@wilson/api/gateway';
import { AuthState } from '@wilson/auth/core';
import { FilterKey } from '@wilson/filter';
import {
  PublicationStatus,
  ShiftConfirmationStatus,
  TimelineActiveFilterRegionEnum,
  UserTimelines,
} from '@wilson/interfaces';
import { WilsonState } from '@wilson/non-domain-specific/decorators/wilson-state';
import {
  FilterSettingsState,
  SaveFilterParamQueriesInCache,
} from '@wilson/preferred-settings/state';
import { endOfDay, startOfDay } from 'date-fns';
import { isEqual } from 'lodash';
import {
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
  firstValueFrom,
  map,
  Observable,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs';
import { ReleaseStatus } from '../interfaces';
import { TimelineFetchUsersWithFilters } from '../shift-timeline-data/shift-timeline-data.actions';
import { ShiftTimelineDataState } from '../shift-timeline-data/shift-timeline-data.state';

interface Filters {
  userIds: string[];
  roleIds: string[];
  professionIds: string[];
  userLabels: string[];
  orgUnitIds: string[];
  homeLocationIds: string[];
  absencesCategoryIds: string[];
  shiftCategoryIds: string[];
  releaseStatus: PublicationStatus | null;
  shiftLabels: string[];
  projects: string[];
  confirmedDeclined: ShiftConfirmationStatus[];
  shiftOrgUnitIds: string[];
  shiftProfessionIds: string[];
  agreementIds: string[];
  timelineActiveFilterRegion: TimelineActiveFilterRegionEnum[];
}

export type ShiftTimelineFilterKeys = Extract<
  FilterKey,
  | 'agreements'
  | 'absences'
  | 'users'
  | 'role'
  | 'orgUnit'
  | 'labels'
  | 'profession'
  | 'homeLocation'
  | 'releaseStatus'
  | 'acceptanceStatus'
  | 'projects'
  | 'shiftCategory'
  | 'shiftOrgUnit'
  | 'shiftLabels'
  | 'shiftProfession'
  | 'renderUnmatchedElements'
  | 'timelineActiveFilterRegion'
>;
@Injectable({
  providedIn: 'root',
})
export class ShiftTimelineFilterStateService {
  private readonly shiftTimelineFilterKeys: ShiftTimelineFilterKeys[] = [
    'agreements',
    'absences',
    'users',
    'role',
    'orgUnit',
    'labels',
    'profession',
    'homeLocation',
    'releaseStatus',
    'acceptanceStatus',
    'projects',
    'shiftCategory',
    'shiftOrgUnit',
    'shiftLabels',
    'shiftProfession',
    'renderUnmatchedElements',
    'timelineActiveFilterRegion',
  ] as const;

  @WilsonState<string[]>([])
  userIds!: string[];
  userIds$!: Observable<string[]>;

  @WilsonState<string[]>([])
  orgUnitIds!: string[];
  orgUnitIds$!: Observable<string[]>;

  @WilsonState<string[]>([])
  homeLocationIds!: string[];
  homeLocationIds$!: Observable<string[]>;

  @WilsonState<string[]>([])
  agreementIds!: string[];
  agreementIds$!: Observable<string[]>;

  @WilsonState<string[]>([])
  shiftOrgUnitIds!: string[];
  shiftOrgUnitIds$!: Observable<string[]>;

  @WilsonState<string[]>([])
  roleIds!: string[];
  roleIds$!: Observable<string[]>;

  @WilsonState<string[]>([])
  absencesCategoryIds!: string[];
  absencesCategoryIds$!: Observable<string[]>;

  @WilsonState<string[]>([])
  shiftCategoryIds!: string[];
  shiftCategoryIds$!: Observable<string[]>;

  @WilsonState<ReleaseStatus | null>(null)
  releaseStatus!: ReleaseStatus | null;
  releaseStatus$!: Observable<ReleaseStatus | null>;

  @WilsonState<string | null>(null)
  searchTerm!: string | null;
  searchTerm$!: Observable<string | null>;

  @WilsonState<string[]>([])
  professionIds!: string[];
  professionIds$!: Observable<string[]>;

  @WilsonState<string[]>([])
  shiftProfessionIds!: string[];
  shiftProfessionIds$!: Observable<string[]>;

  @WilsonState<string[]>([])
  labels!: string[];
  labels$!: Observable<string[]>;

  @WilsonState<string[]>([])
  shiftLabels!: string[];
  shiftLabels$!: Observable<string[]>;

  @WilsonState<string[]>([])
  projectIds!: string[];
  projectIds$!: Observable<string[]>;

  @WilsonState<ShiftConfirmationStatus[]>([])
  confirmedDeclined!: ShiftConfirmationStatus[];
  confirmedDeclined$!: Observable<ShiftConfirmationStatus[]>;

  @WilsonState(false)
  showFilterDrawer!: boolean;
  showFilterDrawer$!: Observable<boolean>;

  @WilsonState(true)
  renderUnmatchedElements!: boolean;
  renderUnmatchedElements$!: Observable<boolean>;

  @WilsonState<TimelineActiveFilterRegionEnum[]>([])
  timelineActiveFilterRegion!: TimelineActiveFilterRegionEnum[];
  timelineActiveFilterRegion$!: Observable<TimelineActiveFilterRegionEnum[]>;

  readonly filters$: Observable<Filters> = combineLatest([
    this.orgUnitIds$,
    this.homeLocationIds$,
    this.agreementIds$,
    this.userIds$,
    this.roleIds$,
    this.professionIds$,
    this.labels$,
    this.absencesCategoryIds$,
    this.shiftCategoryIds$,
    this.releaseStatus$,
    this.shiftLabels$,
    this.projectIds$,
    this.confirmedDeclined$,
    this.shiftOrgUnitIds$,
    this.shiftProfessionIds$,
    this.renderUnmatchedElements$,
    this.timelineActiveFilterRegion$,
  ]).pipe(
    map(
      ([
        orgUnitIds,
        homeLocationIds,
        agreementIds,
        userIds,
        roleIds,
        professionIds,
        userLabels,
        absencesCategoryIds,
        shiftCategoryIds,
        releaseStatus,
        shiftLabels,
        projects,
        confirmedDeclined,
        shiftOrgUnitIds,
        shiftProfessionIds,
        renderUnmatchedElements,
        timelineActiveFilterRegion,
      ]) => ({
        userIds,
        roleIds,
        professionIds,
        userLabels,
        orgUnitIds,
        homeLocationIds,
        agreementIds,
        absencesCategoryIds,
        shiftCategoryIds,
        releaseStatus,
        shiftLabels,
        projects,
        confirmedDeclined,
        shiftOrgUnitIds,
        shiftProfessionIds,
        renderUnmatchedElements,
        timelineActiveFilterRegion,
      }),
    ),
    debounceTime(10),
  );

  private usersDictionary$: Observable<Record<string, UserTimelines>> =
    this.store.select(ShiftTimelineDataState.usersDictionary).pipe(
      distinctUntilChanged(
        (
          previousValue: Record<string, UserTimelines>,
          currentValue: Record<string, UserTimelines>,
        ) => {
          return (
            Object.keys(previousValue).length ===
            Object.keys(currentValue).length
          );
        },
      ),
    );

  private filterUserIds$ = combineLatest([
    this.store
      .select(ShiftTimelineDataState.visibleDateRange)
      .pipe(filter((dateRange) => !!dateRange.start && !!dateRange.end)),
    this.filters$,
  ]).pipe(
    debounceTime(50),
    distinctUntilChanged(
      (
        [previousVisibleDateRange, previousFilters],
        [newVisibleDateRange, newFilters],
      ) => {
        const isStartDateSame =
          previousVisibleDateRange.start &&
          newVisibleDateRange.start &&
          previousVisibleDateRange.start.toString() ===
            newVisibleDateRange.start.toString();

        const isEndDateSame =
          previousVisibleDateRange.end &&
          newVisibleDateRange.end &&
          previousVisibleDateRange.end.toString() ===
            newVisibleDateRange.end.toString();

        const areFiltersSame = isEqual(previousFilters, newFilters);

        const response = isStartDateSame && isEndDateSame && areFiltersSame;

        return !!response;
      },
    ),
    switchMap(([dateRange, filters]) => {
      const filterOptions: UserFiltersDTO = {
        from: startOfDay(dateRange.start as Date).toISOString(),
        to: endOfDay(dateRange.end as Date).toISOString(),
      };

      const userFilters = this.createUserFilters(filters);
      const hasUserFilters = userFilters && Object.keys(userFilters).length > 0;

      if (hasUserFilters) {
        filterOptions.userFilters = userFilters;
      }

      const shiftFilters = this.createShiftFilters(
        filters,
        TimelineActiveFilterRegionEnum.AssignedRegion,
      );
      const absenceFilters = this.createAbsenceFilters(filters);

      if (shiftFilters && Object.keys(shiftFilters).length > 0) {
        filterOptions.shiftFilters = shiftFilters;
      }
      if (absenceFilters && Object.keys(absenceFilters).length > 0) {
        filterOptions.absenceFilters = absenceFilters;
      }

      this.store.dispatch(new TimelineFetchUsersWithFilters(filterOptions));

      return this.store.select(ShiftTimelineDataState.filterUserIds);
    }),
  );

  readonly filteredUsers$ = combineLatest([
    this.usersDictionary$,
    this.filterUserIds$,
    this.searchTerm$.pipe(debounceTime(300)),
  ]).pipe(
    map(([usersDictionary, filterUserIds, searchTerm]) => {
      return Object.values(usersDictionary).filter((user) => {
        const isUserInFilterUserIds = filterUserIds.includes(user.id as string);
        const lowerCaseSearchTerm = searchTerm?.toLowerCase();
        const isUserInSearchTerm = lowerCaseSearchTerm
          ? user.firstName.toLowerCase().includes(lowerCaseSearchTerm) ||
            user.lastName.toLowerCase().includes(lowerCaseSearchTerm)
          : true;

        return isUserInFilterUserIds && isUserInSearchTerm;
      });
    }),
    shareReplay(1),
  );

  constructor(
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  public async generateShiftFilterOptionsForUnassignedRegion(
    startDate: Date,
    endDate: Date,
  ) {
    const options: UserFiltersDTO = {
      from: startOfDay(startDate).toISOString(),
      to: endOfDay(endDate).toISOString(),
    };
    const filters = await firstValueFrom(this.filters$);
    const shiftFilters = this.createShiftFilters(
      filters,
      TimelineActiveFilterRegionEnum.UnassignedRegion,
    );

    if (shiftFilters && Object.keys(shiftFilters).length > 0) {
      options.shiftFilters = shiftFilters;
    }

    return options;
  }

  public async generateFilterOptionsForAssignedRegion(
    startDate: Date,
    endDate: Date,
    userIds: string[] | null,
  ) {
    const options: UserFiltersDTO = {
      from: startOfDay(startDate).toISOString(),
      to: endOfDay(endDate).toISOString(),
    };
    const filters = await firstValueFrom(this.filters$);
    const userFilters = this.createUserFilters(filters);
    const shiftFilters = this.createShiftFilters(
      filters,
      TimelineActiveFilterRegionEnum.AssignedRegion,
    );
    const absenceFilters = this.createAbsenceFilters(filters);

    if (userFilters && Object.keys(userFilters).length > 0) {
      options.userFilters = userFilters;
    }
    if (shiftFilters && Object.keys(shiftFilters).length > 0) {
      options.shiftFilters = shiftFilters;
    }
    if (absenceFilters && Object.keys(absenceFilters).length > 0) {
      options.absenceFilters = absenceFilters;
    }

    if (userIds?.length) {
      options.userFilters = options.userFilters ?? {};
      options.userFilters.userIds = userIds;
    }

    return options;
  }

  private createUserFilters(filters: Filters): UserFiltersDTO['userFilters'] {
    const userFilters: UserFiltersDTO['userFilters'] = {};
    if (filters.roleIds.length > 0) {
      userFilters.roleIds = filters.roleIds;
    }
    if (filters.orgUnitIds.length > 0) {
      userFilters.organizationalUnitIds = filters.orgUnitIds;
    }
    if (filters.userLabels.length > 0) {
      userFilters.labelIds = filters.userLabels;
    }
    if (filters.professionIds.length > 0) {
      userFilters.professionIds = filters.professionIds;
    }
    if (filters.userIds.length > 0) {
      userFilters.userIds = filters.userIds;
    }
    if (filters.homeLocationIds.length > 0) {
      userFilters.homeLocationIds = filters.homeLocationIds;
    }
    return userFilters;
  }

  private createShiftFilters(
    filters: Filters,
    regionToFilter: TimelineActiveFilterRegionEnum,
  ): UserFiltersDTO['shiftFilters'] {
    const shiftFilters: UserFiltersDTO['shiftFilters'] = {};
    if (filters.timelineActiveFilterRegion.includes(regionToFilter)) {
      if (filters.shiftCategoryIds.length > 0) {
        shiftFilters.categoryIds = filters.shiftCategoryIds;
      }
      if (filters.releaseStatus) {
        shiftFilters.publicationStatus = [filters.releaseStatus];
      }
      if (filters.confirmedDeclined.length > 0) {
        shiftFilters.confirmationStatus = filters.confirmedDeclined;
      }
      if (filters.projects.length > 0) {
        shiftFilters.projectIds = filters.projects;
      }
      if (filters.shiftOrgUnitIds.length > 0) {
        shiftFilters.organizationalUnitIds = filters.shiftOrgUnitIds;
      }
      if (filters.shiftLabels.length > 0) {
        shiftFilters.labelIds = filters.shiftLabels;
      }
      if (filters.shiftProfessionIds.length > 0) {
        shiftFilters.professionIds = filters.shiftProfessionIds;
      }
      if (filters.agreementIds.length > 0) {
        shiftFilters.agreementIds = filters.agreementIds;
      }
    }
    return shiftFilters;
  }

  private createAbsenceFilters(
    filters: Filters,
  ): UserFiltersDTO['absenceFilters'] {
    const absenceFilters: UserFiltersDTO['absenceFilters'] = {};
    if (filters.absencesCategoryIds.length > 0) {
      absenceFilters.categoryIds = filters.absencesCategoryIds;
    }
    return absenceFilters;
  }

  public setRenderUnmatchedElements(value: boolean) {
    this.renderUnmatchedElements = value;
  }

  public setFilterRegion(value: TimelineActiveFilterRegionEnum[]) {
    this.timelineActiveFilterRegion = value;
  }

  public filterCachingEventListener() {
    return this.filters$.pipe(
      debounceTime(100),
      distinctUntilChanged((prev, curr) => isEqual(prev, curr)),
      tap(() => {
        //setTimeout is necessary because the queryParams are not updated immediately
        setTimeout(() => {
          const currentUserId = this.store.selectSnapshot(AuthState.userId);
          if (currentUserId) {
            const shiftTimelineFilters = this.pickOnlyShiftTimelineFilters(
              this.route.snapshot.queryParams,
            );
            this.store.dispatch(
              new SaveFilterParamQueriesInCache(
                currentUserId,
                shiftTimelineFilters,
              ),
            );
          }
        }, 0);
      }),
    );
  }

  public setFilterSettings(userId: string) {
    const params = this.route.snapshot.queryParams;
    const isFilterSet = this.shiftTimelineFilterKeys.some((key) =>
      Object.prototype.hasOwnProperty.call(params, key),
    );

    if (!isFilterSet) {
      this.loadFiltersFromCache(userId);
    }
  }

  protected loadFiltersFromCache(userId: string): void {
    const filtersInCache =
      this.store.selectSnapshot(
        FilterSettingsState.getFilterSettings(userId),
      ) || this.initializeDefaultFilters(userId);

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: filtersInCache,
      queryParamsHandling: 'merge',
    });
  }

  private initializeDefaultFilters(userId: string) {
    const defaultTimelineActiveFilterRegion = {
      timelineActiveFilterRegion: `${TimelineActiveFilterRegionEnum.UnassignedRegion},${TimelineActiveFilterRegionEnum.AssignedRegion}`,
      renderUnmatchedElements: 'true',
    };

    this.store.dispatch(
      new SaveFilterParamQueriesInCache(
        userId,
        defaultTimelineActiveFilterRegion,
      ),
    );

    return this.store.selectSnapshot(
      FilterSettingsState.getFilterSettings(userId),
    );
  }

  public areTimelineFiltersSet(): boolean {
    const params = this.route.snapshot.queryParams;

    const excludedKeys: ShiftTimelineFilterKeys[] = [
      'renderUnmatchedElements',
      'timelineActiveFilterRegion',
    ];
    const containsTimelineFilters = this.shiftTimelineFilterKeys.some(
      (key) =>
        excludedKeys.indexOf(key) === -1 &&
        Object.prototype.hasOwnProperty.call(params, key),
    );

    const hasSearchTerm = !!this.searchTerm?.trim();
    return containsTimelineFilters || hasSearchTerm;
  }

  public pickOnlyShiftTimelineFilters(params: Params) {
    return Object.entries(params).reduce((acc, [key, value]) => {
      if (
        this.shiftTimelineFilterKeys.includes(key as ShiftTimelineFilterKeys)
      ) {
        acc[key as ShiftTimelineFilterKeys] = value;
      }
      return acc;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    }, {} as Record<ShiftTimelineFilterKeys, any>);
  }
}
